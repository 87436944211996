import { defineStore } from 'pinia'
import ViewController from '~/controllers/views'
import type { JView } from '~/models/views/jView'

export const viewsStore = defineStore({
  id: 'views',
  state: () => ({
    filters: [] as any[],
    views: [] as JView[],
  }),
  actions: {
    async loadViews(): Promise<any> {
      const views = await ViewController.getUserViews()

      this.views = views
    },
    getViewById(viewId: string): JView | undefined {
      return this.views.find((e) => e.id === viewId)
    },
    async updateViews(views: JView[]) {
      views.forEach((notification) => {
        const currentNotifIndex = this.views.findIndex(
          (e) => e.id === notification.id,
        )

        currentNotifIndex >= 0
          ? this.views.splice(currentNotifIndex, 1, notification)
          : this.views.push(notification)
      })
    },
    removeView(viewId) {
      const viewPosition = this.views.map((e) => e.id).indexOf(viewId)

      this.views.splice(viewPosition, 1)
    },
  },
})

<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },

  contentClass: {
    type: String,
    required: false,
    default: '',
  },
})

const emit = defineEmits<{
  (e: 'save'): void
  (e: 'actionOnClose'): void
  (e: 'update:modelValue', val: boolean): void
}>()

const closeModal = () => {
  emit('update:modelValue', false)
  emit('actionOnClose')
}
</script>

<template>
  <div
    v-if="props.modelValue"
    class="fixed inset-0 z-20"
  >
    <div
      id="modal-wrapper"
      class="flex min-h-full items-center justify-center bg-black/40 p-4 text-center sm:p-0"
      :onclick="() => closeModal()"
    >
      <div
        :onclick="(e) => e.stopPropagation()"
        class="content rounded-md bg-white p-8"
        :class="$props.contentClass"
      >
        <slot name="body" />
      </div>
    </div>
  </div>
</template>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>

import { defineStore } from 'pinia'
import { actionsStore } from './actions'
import { usersStore } from './users'
import {
  JProject,
  PriorityStatus,
  ProjectStatus,
} from '~/models/projects/JProject'

export const projectsStore = defineStore({
  id: 'projects',
  state: () => ({
    projects: [] as JProject[],
  }),
  actions: {
    async loadProjects() {
      const projects: JProject[] = [
        {
          id: 1,
          name: 'projects.demo.project_1_title',
          description: 'projects.demo.project_1_description',
          assigneesIds: [usersStore().user.id],
          deadline: new Date(),
          priority: PriorityStatus.HIGH,
          budget: 12350,
          status: ProjectStatus.IN_PROGRESS,
          actions: [],
          workplaces: 'UAP3',
        },
        {
          id: 2,
          name: 'projects.demo.project_2_title',
          description: '',
          assigneesIds: [usersStore().user.id],
          deadline: new Date(),
          priority: PriorityStatus.LOW,
          budget: 10550,
          status: ProjectStatus.IN_PROGRESS,
          actions: [],
          workplaces: 'UAP4',
        },
        {
          id: 3,
          name: 'projects.demo.project_3_title',
          description: '',
          assigneesIds: [usersStore().user.id],
          deadline: new Date(),
          priority: PriorityStatus.MEDIUM,
          budget: 8800,
          status: ProjectStatus.NEW,
          actions: [],
          workplaces: 'UAP2',
        },
      ]

      projects.map((project) => {
        project.actions = actionsStore().actions.filter(
          (action) => action.projectId === project.id,
        )
      })

      this.projects = projects
    },
    findProjectById(id: number) {
      return this.projects.find((e) => e.id === id)
    },
  },
})

import { gridStore } from '~/store/grid'

export default class TextAreaCellEditor {
  focusAfterAttached: any
  eInput: any
  params: any
  cancelBeforeStart: any
  // gets called once before the renderer is used
  init(params: any) {
    const cell = gridStore().getGrid.getCellByIndex(
      params?.rowIndex,
      params.colDef?.index - params.data?.shiftIndex,
    )

    // we only want to highlight this cell if it started the edit, it is possible
    // another cell in this row started the edit
    const value = cell?.latestValue || ''

    params.value = value
    this.focusAfterAttached = params.cellStartedEdit
    // create the cell
    this.eInput = document.createElement('textarea')
    this.eInput.style.resize = 'none'
    this.eInput.style.width = '100%'
    this.eInput.style.height = '100%'
    this.params = params
    this.eInput.value = value

    this.eInput.id = `cell_input_${params?.colDef?.colId}_${params?.rowIndex}`
    this.eInput.addEventListener('keydown', this.handleKeyDown)
  }

  // gets called once when grid ready to insert the element
  getGui() {
    return this.eInput
  }

  // focus and select can be done after the gui is attached
  afterGuiAttached() {
    // only focus after attached if this cell started the edit
    if (this.focusAfterAttached) this.eInput.focus()
    // this.eInput.select()
  }

  // returns the new value after editing
  isCancelBeforeStart() {
    return this.cancelBeforeStart
  }

  restrictTextAreaToNLines = (numberOfLines: number) => {
    const { selectionStart, value } = this.eInput
    const lines = value.split('\n')
    const currentRow = lines.slice(0, selectionStart).length

    if (lines.length < numberOfLines || currentRow < numberOfLines - 1) {
      const newValue = `${value.substring(0, selectionStart)}\n${value.substring(selectionStart)}`
      this.eInput.value = newValue
      this.eInput.setSelectionRange(selectionStart + 1, selectionStart + 1)
    }
  }

  handleKeyDown = (event: any) => {
    if (event.shiftKey && event.key === 'Enter') {
      event.preventDefault()

      this.restrictTextAreaToNLines(4)
    }
  }

  // returns the new value after editing
  getValue() {
    return this.eInput.value.replace(',', '.')
  }

  // when we tab onto this editor, we want to focus the contents
  focusIn() {
    const eInput = this.getGui()
    eInput.focus()
    eInput.select()
  }

  // when we tab out of the editor, this gets called
  focusOut() {
    // but we don't care, we just want to print it for demo purposes
  }
}

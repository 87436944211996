import { hydrate } from '~/utils/object'

export class JInputData {
  [x: string]: any
  id = ''
  col_id = 0
  row_id = 0
  step_id: any = ''
  type = ''
  report_id = ''
  value = ''
  update_date = ''
  updated_by = ''
  client_id = ''
  double_check_result = false
  double_checked_by = ''
  double_checked_at = ''
  site_id = ''
  is_valid = false
  reason = ''
  is_forced = ''
  context: JInputDataContext = new JInputDataContext({})

  constructor(object: typeof JInputDataContext | {}) {
    hydrate(this, object)
  }
}

export class JInputDataContext {
  product_id = ''
  session_id = ''
  production_order_id = ''
  calculContext = [] as string[]

  constructor(object: typeof JInputDataContext | {}) {
    hydrate(this, object)
  }
}

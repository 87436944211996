import Cell from './Cell'
import type { JInputData } from '~/models/report/jInputData'
import { type JStep, StepType } from '~/models/documents/jStep'

export class TimeCell extends Cell {
  hour: string | undefined
  minutes: string | undefined

  constructor(
    rowIndex: number,
    colIndex: number,
    inputs: JInputData[],
    step: JStep,
    isHistory: boolean,
    onExport: false,
    report_id: null,
  ) {
    if (step?.type !== StepType.Time) throw new Error('Type mismatch')

    super(rowIndex, colIndex, inputs, step, isHistory, onExport, report_id)

    const timeValue = this.getLatestInputData()?.value?.split(':')
    this.hour = timeValue?.[0]
    this.minutes = timeValue?.[1]
  }

  get latestValue(): string {
    return this.hour && this.minutes ? `${this.hour}:${this.minutes}` : ''
  }

  setHour(hour: string): void {
    this.hour = hour
  }

  setMinutes(minutes: string): void {
    this.minutes = minutes
  }
}

export default TimeCell

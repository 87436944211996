// register vue composition api globally
import { ViteSSG } from 'vite-ssg'
import generatedRoutes from 'virtual:generated-pages'
import { setupLayouts } from 'virtual:generated-layouts'
import Antd from 'ant-design-vue'
import VueDragscroll from 'vue-dragscroll'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import { VueQueryPlugin } from '@tanstack/vue-query'
import App from './App.vue'
import { setupCalendar } from 'v-calendar'

// Use calendar defaults (optional)
import rbac from './config/rbac'

// your custom styles here
import './styles/main.scss'
// import loggerHelper from "~/helpers/LoggerHelper";
import { datadogRum } from '@datadog/browser-rum'
import {ENVIRONMENT} from "~/config";

export const routes = setupLayouts(generatedRoutes)

// https://github.com/antfu/vite-ssg
export const createApp = ViteSSG(App, { routes }, (ctx) => {
  // install all modules under `modules/`
  Object.values(import.meta.globEager('./modules/*.ts')).map((i) =>
    i.install?.(ctx),
  )
  ctx.app.use(Antd)
  ctx.app.use(VueDragscroll)
  ctx.app.use(autoAnimatePlugin)
  ctx.app.use(rbac)
  ctx.app.use(VueQueryPlugin)
  ctx.app.use(setupCalendar, {})

  if(ENVIRONMENT === 'prod') {
    ctx.app.config.errorHandler = (err, instance, info) => {
      datadogRum.addError(err, {
        component: instance?.$options?.name || 'anonymous',
        info,
      });
    };
  }
})

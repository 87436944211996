<script setup lang="ts">
import _ from 'lodash'
import { Line } from 'vue-chartjs'
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import { StepType } from '~/models/documents/jStep'
import { settingsStore } from '~/store/settings'
import { SettingsType } from '~/models/settings/settings'
import CellRendererFile from '~/components/UI/CellRendererFile.vue'
import type { Controle } from '~/models/operator'
import { Icons } from '~/models/Icon'
import { InfiniteValue } from '~/models/documents/documentSettings'
import { isUndefinedOrNullOrEmptyOrNan } from '~/utils/object'
import type { ChartJsOptionsData } from '~/models/Chart'
import {
  CellMeasureValidation,
  ValidationType,
} from '~/services/steps/CellMeasureValidation'
import { gridStore } from '~/store/grid'

const props = defineProps({
  params: {
    type: Object,
    required: true,
    default: {} as any,
  },
})

const { t } = useI18n()

const state = reactive({
  details: props.params?.data?.details,
  isHistory: props.params?.data?.isHistory,
  unit: props.params?.data?.details?.unit,
  allControles: [] as Controle[],
  graphData: {} as ChartJsOptionsData,
  openModal: false,
})

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

interface ChartJsOptionsData {
  labels: string[]
  datasets: {
    label: string
    data: number[]
    borderColor?: string
    pointBackgroundColor?: string | string[]
    pointBorderColor?: string
    backgroundColor: string
    tension?: number
    pointRadius?: number
  }[]
}

const allControles = computed(() => {
  let answers = _.cloneDeep(
    gridStore().getGrid.getReport()?.steps[props.params?.rowIndex]?.answers,
  )

  if (!answers?.length) return
  answers = _.chain(answers)
    .groupBy('col_id')
    .map((e) => {
      const latestAnswer = _.chain(e).first()?.value()
      latestAnswer.value = latestAnswer?.value?.length
        ? Number(latestAnswer?.value)
        : null

      return latestAnswer
    })
    .value()
  return answers
})

const graphData = computed(() => {
  if (!props.params || !isMeasure) return
  const data = {} as ChartJsOptionsData

  const step = {
    ...props.params?.data,
    ...props.params?.data?.details,
  }
  const tolerance =
    settingsStore().filterSettings(SettingsType.tolerance)[0]?.value || 0
  const [boundariesMin, boundariesMax] =
    new CellMeasureValidation().getBoundaries({ step, indexCol: -1, tolerance })

  const minValue = boundariesMin[0]
  const maxValue = boundariesMax[1]

  const commonConfig = {
    tension: 0.1,
    pointBorderColor: 'transparent',
    pointBackgroundColor: 'transparent',
  }

  data.labels = allControles?.value?.map(
    (e: Controle) => `${t('report.control')} ${e.col_id + 1}`,
  )
  const noMinMax = !(
    minValue === maxValue && Number(props.params.data.details.goal) === minValue
  )

  const getDotColors = () => {
    return allControles?.value?.map((e) => {
      const isOrange = new CellMeasureValidation(
        ValidationType.WARNING,
      ).validate({
        cellValue: e.value?.toString(),
        step,
        tolerance,
        indexCol: 0,
      })
      const isRed = new CellMeasureValidation(ValidationType.ERROR).validate({
        cellValue: e.value?.toString(),
        step,
      })

      return isOrange ? '#E98316' : isRed ? '#E4212B' : '#4EB71A'
    })
  }

  data.datasets = [
    {
      label: t('report.control'),
      data: allControles?.value?.map((e) => Number(e.value)),
      borderColor: 'rgb(75, 192, 192)',
      pointBackgroundColor: getDotColors(),
      backgroundColor: 'rgb(75, 192, 192)',
      pointRadius: 5,
    },
  ]

  // Handle target
  if (props.params.data.details.goal && !props.params.data.details.is_dynamic) {
    data.datasets.push({
      label: t('report.target'),
      data: Array(allControles?.value?.length).fill(
        Number(props.params.data.details.goal),
      ),
      borderColor: 'orange',
      backgroundColor: 'orange',
      ...commonConfig,
    })
  }

  if (
    props.params.data.details.is_dynamic &&
    !_.isEmpty(props.params.data.details.last_targets)
  ) {
    const targets = Array.from({ length: allControles?.value?.length })
      .fill(null)
      .map((_, index) => {
        const tar = props.params?.data.details.last_targets.find((target) => {
          if (index + 1 >= target.init_col_id && index <= target.last_col_id)
            return target
          return null
        })

        return tar ? Number(tar.target) : null
      })

    data.datasets.push({
      label: t('report.dynamic_target'),
      data: targets as number[],
      borderColor: 'orange',
      backgroundColor: 'orange',
      ...commonConfig,
    })
  }

  // Handle minimum data line
  if (
    !isUndefinedOrNullOrEmptyOrNan(minValue) &&
    minValue !== InfiniteValue.MIN &&
    noMinMax
  ) {
    data.datasets.push({
      label: 'Min',
      data: Array(allControles?.value?.length).fill(minValue),
      borderColor: 'red',
      backgroundColor: 'red',
      ...commonConfig,
    })
  }
  // Handle maximum data line
  if (
    !isUndefinedOrNullOrEmptyOrNan(maxValue) &&
    maxValue !== InfiniteValue.MAX &&
    noMinMax
  ) {
    data.datasets.push({
      label: 'Max',
      data: Array(allControles?.value?.length).fill(maxValue),
      borderColor: 'red',
      backgroundColor: 'red',
      ...commonConfig,
    })
  }
  return data
})

const isMeasure = props.params?.data?.type === StepType.Measure
</script>

<template>
  <div class="flex flex-col items-center gap-y-2">
    <div
      :id="`id_${props.params?.colDef?.reportId}_${params.data.num_step}`"
      class="flex-center flex h-8 w-8 self-center rounded-md bg-cloudygray-100 p-0 text-base font-semibold"
    >
      {{ params.data.num_step }}
    </div>
    <div
      v-if="props.params?.data.file !== undefined"
      class="h-8"
    >
      <CellRendererFile :params="params" />
    </div>
    <div
      v-if="isMeasure"
      class="cursor-pointer justify-center rounded border border-primary bg-secondary p-1"
      @click="state.openModal = true"
    >
      <component
        :is="Icons.CHART_LINE"
        class="h-4 w-4"
      />
    </div>
    <OModal
      :open="state.openModal"
      @close="state.openModal = false"
    >
      <template #content>
        <Line
          id="my-chart-id"
          :options="{
            responsive: true,
          }"
          :data="graphData"
        />
      </template>
    </OModal>
  </div>
</template>

<style scoped></style>

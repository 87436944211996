import { defineStore } from 'pinia'
import _ from 'lodash'
import { apiStore } from '~/store/api'

export const groupsStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: 'groups',
  // a function that returns a fresh state
  state: () => ({
    groups: [] as any[],
  }),

  // optional getters
  getters: {
    getGroups(): any {
      return this.groups
    },
  },
  // optional actions
  actions: {
    async loadGroups(forceFetch = false): Promise<any> {
      const api = apiStore().getApiClient

      const groups = await api.getGroups()
      this.groups = groups || []
    },

    findGroup(id: number) {
      return this.groups.find((group: any) => group?.id == id)
    },
    findGroupByName(name: string) {
      return this.groups.find((group: any) => group?.name === name)
    },
    findGroupsByIds(ids: number[]) {
      return this.groups.filter((group) => ids.some((id) => id == group.id))
    },
    findUsersForGroups(groupsIds) {
      const groups = this.groups.filter((group) => groupsIds.includes(group.id))
      let result = []
      groups?.forEach((group) => {
        result = _.union(
          result,
          group.users.map((user) => user.id),
        )
      })

      return result
    },
  },
})

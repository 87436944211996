export default class WrongAuthenticationMethod extends Error {
  code: string

  constructor(
    message = 'you are using the wrong authentication method for this user',
  ) {
    super(message)
    this.name = 'WrongAuthenticationMethod'
    this.code = 'auth/wrong-method'
  }
}

import { createTRPCProxyClient, httpLink } from '@trpc/client'

import type { AppRouter } from '@core-server/trpc/router'
import apiHelper from '~/helpers/ApiHelper'

if (!import.meta.env.VITE_API_URL)
  throw new Error('VITE_API_URL is not defined')

export const tCore = createTRPCProxyClient<AppRouter>({
  links: [
    httpLink({
      url: `${import.meta.env.VITE_API_URL}/trpc`,
      async headers() {
        return {
          authorization: await apiHelper.getAccessToken(),
        }
      },
    }),
  ],
})

import * as _ from 'lodash'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore'
import localForage from 'localforage'
import { addDays } from 'date-fns'
import { db } from '~/services/firebase'
import { gS } from '~/store/global'

async function getCachedDataIfExists(cacheKey) {
  if (gS().canUseCache) {
    const cachedItem: any = await localForage.getItem(cacheKey)

    if (cachedItem && cachedItem.expiryDate > new Date().getTime())
      return cachedItem.data
  }

  return false
}

/* DbHelper v9 */
export default class DBHelper {
  db: any
  constructor() {
    this.db = db
  }

  generateId(collectionName: string) {
    const docRef = doc(collection(db, collectionName))
    return docRef.id
  }

  async setAndUpdateDataToCollection(
    collection: string,
    id: string,
    data: any,
    merge = false,
  ) {
    const docRef = doc(db, collection, id)

    return await setDoc(docRef, data, { merge })
  }

  async batchAdd(collectionName: string, data: any) {
    const batchArray = []
    batchArray.push(writeBatch(db))
    let operationCounter = 0
    let batchIndex = 0

    for (const document of data) {
      const docRef = doc(collection(db, collectionName)) //  doc(db, collection)
      batchArray[batchIndex].set(docRef, document)
      document.id = docRef.id
      operationCounter++
      if (operationCounter === 499) {
        batchArray.push(writeBatch(db))
        batchIndex++
        operationCounter = 0
      }
    }

    batchArray.forEach(async (batch: any) => await batch.commit())
    return data
  }

  async batchUpdate(collection: string, data: any) {
    const batchArray = []
    batchArray.push(writeBatch(db))
    let operationCounter = 0
    let batchIndex = 0
    for (const document of data) {
      const docRef = doc(db, collection, document.id)
      const docBactch = { ...document }
      delete docBactch.id
      batchArray[batchIndex].update(docRef, docBactch)
      operationCounter++
      if (operationCounter === 499) {
        batchArray.push(writeBatch(db))
        batchIndex++
        operationCounter = 0
      }
    }
    batchArray.forEach(async (batch: any) => await batch.commit())
    return batchArray
  }

  async batchDelete(collection: string, data: any) {
    const batchArray = []
    batchArray.push(writeBatch(db))
    let operationCounter = 0
    let batchIndex = 0
    for (const document of data) {
      const docRef = doc(db, collection, document.id)
      batchArray[batchIndex].delete(docRef)
      operationCounter++
      if (operationCounter === 499) {
        batchArray.push(this.db.batch())
        batchIndex++
        operationCounter = 0
      }
    }

    batchArray.forEach(async (batch: any) => await batch.commit())
    return batchArray
  }

  async setDataToCollection(collectionName: string, id: string, data: any) {
    const docRef = collection(db, collectionName)
    return await setDoc(doc(docRef, id), data)
  }

  async addDataToCollection(collectionName: string, data: any) {
    return await addDoc(collection(db, collectionName), data)
  }

  async updateDataToCollection(collectionName: string, id: string, data: any) {
    const docRef = doc(db, collectionName, id)

    return await updateDoc(docRef, data)
  }

  async deleteData(collectionName: string, id: string) {
    return await deleteDoc(doc(db, collectionName, id))
  }

  async getAllDataFromCollectionBetweenDates(
    collectionName: string,
    whatIs: string,
    startDate: Date | number,
    endDate: Date | number,
    arrayWhere: Array<any> = [],
  ) {
    const returnArray: Array<any> = []

    const cacheKey = `${collectionName}-${whatIs}-${startDate}-${endDate}`
    const data = await getCachedDataIfExists(cacheKey)
    if (data) return data

    const whereArgsArray = []
    const keys = Object.keys(arrayWhere)
    for (let i = 0; i < keys.length; i += 1) {
      const prop: any = keys[i]
      whereArgsArray.push(where(prop, '==', arrayWhere[prop]))
    }
    whereArgsArray.push(where(whatIs, '>', startDate))
    whereArgsArray.push(where(whatIs, '<', endDate))

    const q = query(collection(db, collectionName), ...whereArgsArray)
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((docSnap: any) => {
      const resultQuery = docSnap.data()
      resultQuery.id = docSnap.id
      returnArray.push(resultQuery)
    })

    await localForage.setItem(cacheKey, {
      data: returnArray,
      expiryDate: addDays(new Date().setHours(0, 2, 0, 0), 1).getTime(),
    })

    return returnArray
  }

  async getAllDataFromCollectionFromIds(collectionName, ids) {
    if (!ids || !ids.length) return []

    const uniqueIds = Array.from(new Set(ids)) // Removing duplicates

    // Chunking the unique ids into groups of 10
    const idChunks = []
    for (let i = 0; i < uniqueIds.length; i += 30)
      idChunks.push(uniqueIds.slice(i, i + 30))

    const fetchData = async (idChunk) => {
      const q = query(
        collection(db, collectionName),
        where('__name__', 'in', idChunk),
      )
      const snapshot = await getDocs(q)
      return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
    }

    // Fetching data for all chunks
    const results = await Promise.all(idChunks.map(fetchData))

    // Flattening the results
    return results.flat()
  }

  // async getAllDataFromCollectionFromIds(
  //   collectionName: string,
  //   equalTo: Array<any>,
  // ) {
  //   const returnArray: Array<any> = []

  //   if (equalTo?.length) {
  //     const equalToUniq = _.uniq(equalTo)
  //     const equalToChunked = _.chunk(equalToUniq, 10)
  //     for (const tenBloc of equalToChunked) {
  //       const q = query(collection(db, collectionName), where('__name__', 'in', tenBloc))
  //       const querySnapshot = await getDocs(q)
  //       querySnapshot.forEach((docSnap: any) => {
  //         const resultQuery = docSnap.data()
  //         resultQuery.id = docSnap.id
  //         returnArray.push(resultQuery)
  //       })
  //     }
  //   }

  //   return returnArray
  // }

  async getAllDataFromCollectionWithWhere(
    collectionName: string,
    whatIs: string,
    equalTo: any,
  ) {
    const returnArray: Array<any> = []

    const cacheKey = `${collectionName}-${whatIs}-${JSON.stringify(equalTo)}`
    const data = await getCachedDataIfExists(cacheKey)
    if (data) return data

    const q = query(
      collection(db, collectionName),
      where(whatIs, '==', equalTo),
    )
    const querySnapshot: any = await getDocs(q).catch((e) =>
      console.error(
        'getAllDataFromCollectionWithWhere',
        collectionName,
        whatIs,
        equalTo,
        e,
      ),
    )
    querySnapshot?.forEach((docSnap: any) => {
      const resultQuery = docSnap.data()
      resultQuery.id = docSnap.id
      returnArray.push(resultQuery)
    })

    await localForage.setItem(cacheKey, {
      data: returnArray,
      expiryDate: addDays(new Date().setHours(0, 2, 0, 0), 1).getTime(),
    })

    return returnArray
  }

  async getAllDataFromCollectionWithWhereInArray(
    collectionName: string,
    whatIs: string,
    equalTo: any,
  ) {
    const returnArray: Array<any> = []

    const cacheKey = `${collectionName}-${whatIs}-${JSON.stringify(equalTo)}`
    const data = await getCachedDataIfExists(cacheKey)
    if (data) return data

    const q = query(
      collection(db, collectionName),
      where(whatIs, 'array-contains', equalTo),
    )
    const querySnapshot: any = await getDocs(q).catch((e) =>
      console.error(
        'getAllDataFromCollectionWithWhereInArray',
        collectionName,
        whatIs,
        equalTo,
        e,
      ),
    )
    querySnapshot.forEach((docSnap: any) => {
      const resultQuery = docSnap.data()
      resultQuery.id = docSnap.id
      returnArray.push(resultQuery)
    })

    await localForage.setItem(cacheKey, {
      data: returnArray,
      expiryDate: addDays(new Date().setHours(0, 2, 0, 0), 1).getTime(),
    })

    return returnArray
  }

  async getAllDataFromCollection(collectionName: string) {
    const returnArray: Array<any> = []

    const cacheKey = `${collectionName}`
    const data = await getCachedDataIfExists(cacheKey)
    if (data) return data

    const q = query(collection(db, collectionName))
    const querySnapshot: any = await getDocs(q).catch((e) =>
      console.error('getAllDataFromCollection', collectionName, e),
    )
    querySnapshot?.forEach((docSnap: any) => {
      const resultQuery = docSnap.data()
      resultQuery.id = docSnap.id
      returnArray.push(resultQuery)
    })

    await localForage.setItem(cacheKey, {
      data: returnArray,
      expiryDate: addDays(new Date().setHours(0, 2, 0, 0), 1).getTime(),
    })

    return returnArray
  }

  async getDocFromCollection<T>(
    collectionName: string,
    docId: string,
  ): Promise<T> {
    let result: any = null

    const cacheKey = `${collectionName}-${docId}`
    const data = await getCachedDataIfExists(cacheKey)
    if (data) return data

    if (docId) {
      const docRef = doc(this.db, collectionName, docId)
      const docSnap: any = await getDoc(docRef).catch(() =>
        console.error('getDocFromCollection', collectionName),
      )

      if (docSnap.exists()) {
        const resultQuery: any = docSnap.data()
        resultQuery.id = docSnap.id
        result = resultQuery
      } else {
        // doc.data() will be undefined in this case
        const error = `getDocFromCollection - ${collection} - ${docId}`
      }
    }

    await localForage.setItem(cacheKey, {
      data: result,
      expiryDate: addDays(new Date().setHours(0, 2, 0, 0), 1).getTime(),
    })

    return result
  }

  async getDocFromCollectionOnSnapshot(
    collectionName: string,
    docId: string,
    callBack: any,
  ) {
    if (docId) {
      const snap = onSnapshot(doc(db, collectionName, docId), (doc: any) => {
        const resultQuery: any = doc.data()
        resultQuery.id = doc.id
        callBack(resultQuery)
        return resultQuery
      })
      return snap
    }
  }

  async getAllDataFromCollectionWithWhereIn(
    collectionName: string,
    whatIs: string,
    equalTo: Array<any>,
    arrayWhere: Array<any> = [],
  ) {
    const returnArray: Array<any> = []

    const cacheKey = `${collectionName}-${whatIs}-${equalTo}-${JSON.stringify(arrayWhere)}`
    const data = await getCachedDataIfExists(cacheKey)
    if (data) return data

    const whereArgsArray = []
    const keys = Object.keys(arrayWhere)
    for (let i = 0; i < keys.length; i += 1) {
      const prop: any = keys[i]
      whereArgsArray.push(where(prop, '==', arrayWhere[prop]))
    }
    const equalToChunked = _.chunk(equalTo, 10)

    for (let i = 0; i < equalToChunked.length; i++) {
      const cloneWhereArgsArray = [...whereArgsArray]
      cloneWhereArgsArray.push(where(whatIs, 'in', equalToChunked[i]))
      const q = query(collection(db, collectionName), ...cloneWhereArgsArray)
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((docSnap: any) => {
        const resultQuery = docSnap.data()
        resultQuery.id = docSnap.id
        returnArray.push(resultQuery)
      })
    }

    await localForage.setItem(cacheKey, {
      data: returnArray,
      expiryDate: addDays(new Date().setHours(0, 2, 0, 0), 1).getTime(),
    })

    return returnArray
  }

  async getAllDataFromCollectionWithAll(
    collectionName: string,
    constraints: any,
  ) {
    const returnArray: Array<any> = []

    const cacheKey = `${collectionName}-${JSON.stringify(constraints)}`
    const data = await getCachedDataIfExists(cacheKey)
    if (data) return data

    const constraintsArgsArray: any[] = []

    const { where_constraints, orderBy_constraints, limit_constraint } =
      constraints || {}

    if (where_constraints) {
      where_constraints.forEach((cond: any) => {
        constraintsArgsArray.push(where(cond.field, cond.compare, cond.value))
      })
    }

    if (orderBy_constraints) {
      orderBy_constraints.forEach((cond: any) => {
        constraintsArgsArray.push(orderBy(cond.field, cond.direction || 'asc'))
      })
    }
    if (limit_constraint) constraintsArgsArray.push(limit(limit_constraint))

    const q = query(collection(db, collectionName), ...constraintsArgsArray)
    const querySnapshot: any = await getDocs(q).catch((error) => {
      console.error(error)
      console.error(
        'getAllDataFromCollectionWithAll',
        collectionName,
        constraints,
      )
    })
    querySnapshot?.forEach((docSnap: any) => {
      const resultQuery = docSnap.data()
      resultQuery.id = docSnap.id
      returnArray.push(resultQuery)
    })

    await localForage.setItem(cacheKey, {
      data: returnArray,
      expiryDate: addDays(new Date().setHours(0, 2, 0, 0), 1).getTime(),
    })

    return returnArray
  }

  async getAllDataFromCollectionWithWhereArray(
    collectionName: string,
    arrayWhere: any,
  ) {
    const returnArray: Array<any> = []

    const cacheKey = `${collectionName}-${JSON.stringify(arrayWhere)}`
    const data = await getCachedDataIfExists(cacheKey)
    if (data) return data

    const whereArgsArray = []
    const keys = Object.keys(arrayWhere)
    for (let i = 0; i < keys.length; i += 1) {
      const prop: any = keys[i]
      whereArgsArray.push(where(prop, '==', arrayWhere[prop]))
    }
    const q = query(collection(db, collectionName), ...whereArgsArray)
    const querySnapshot: any = await getDocs(q).catch(() =>
      console.error(
        'getAllDataFromCollectionWithWhereArray',
        collectionName,
        arrayWhere,
      ),
    )
    querySnapshot.forEach((docSnap: any) => {
      const resultQuery = docSnap.data()
      resultQuery.id = docSnap.id
      returnArray.push(resultQuery)
    })

    await localForage.setItem(cacheKey, {
      data: returnArray,
      expiryDate: addDays(new Date().setHours(0, 2, 0, 0), 1).getTime(),
    })

    return returnArray
  }

  async getDocFromCollectionWithWhere(
    collectionName: string,
    arrayWhere: Array<any>,
  ) {
    let result = null

    const cacheKey = `${collectionName}-${arrayWhere}`
    const data = await getCachedDataIfExists(cacheKey)
    if (data) return data

    const whereArgsArray = []
    const keys = Object.keys(arrayWhere)
    for (let i = 0; i < keys.length; i += 1) {
      const prop: any = keys[i]
      whereArgsArray.push(where(prop, '==', arrayWhere[prop]))
    }

    const q = query(collection(db, collectionName), ...whereArgsArray)
    const querySnapshot: any = await getDocs(q)
    // eslint-disable-next-line no-unreachable-loop
    for (const docSnap of querySnapshot) {
      const resultQuery = docSnap.data()
      resultQuery.id = docSnap.id
      result = resultQuery
      break
    }

    await localForage.setItem(cacheKey, {
      data: result,
      expiryDate: addDays(new Date().setHours(0, 2, 0, 0), 1).getTime(),
    })

    return result
  }

  async setupCollectionSnapshot({ collectionName, checks, callback }: any) {
    const whereChecks = checks.map(({ field, compare, value }: any) =>
      where(field, compare, value),
    )
    const q = query(collection(db, collectionName), ...whereChecks)

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const updatedDocs = (querySnapshot.docs || []).map((change) => ({
        ...change.data(),
        id: change.id,
      }))

      callback(updatedDocs)
    })

    return unsubscribe
  }

  async setupCollectionSnapshotWithArray({
    collectionName,
    workplaceIds,
    callback,
  }: any) {
    if (!workplaceIds.length) return

    const formattedIds = workplaceIds.map((id) => `workplace_${id}_instruction`)
    const whereCheck = where('id', 'in', formattedIds)

    const q = query(collection(db, collectionName), whereCheck)

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const updatedDocs = (querySnapshot.docs || []).map((change) => ({
        ...change.data(),
        id: change.id,
      }))

      callback(updatedDocs)
    })

    return unsubscribe
  }
}

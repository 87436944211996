<script setup lang="ts">
import { ColumnState } from '~/utils/report'
import { Icons } from '~/models/Icon'
import { alertDrawerStore } from '~/store/alertDrawerState'
import { gridStore } from '~/store/grid'
import { formatDate } from '~/utils/formatter'
import type Grid from '~/grid/Grid'

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
})

const displayCheckboxes = computed(() => gridStore().isCheckboxDisplayed)

const startDate = computed(() => {
  const updateDate =
    headerComponentParams?.value?.firstUpdateOnColumn?.update_date
  return updateDate ? formatDate(new Date(updateDate)) : ''
})

const lastDate = computed(() => {
  const updateDate =
    headerComponentParams?.value?.lastUpdateOnColumn?.update_date

  return updateDate ? formatDate(new Date(updateDate)) : ''
})

const colId = computed(() => props.params.column?.colDef?.index)

const grid = computed<Grid>(() => {
  if (props.params?.column?.colDef?.reportId) {
    return gridStore(String(props.params?.column?.colDef?.reportId))?.getGrid
  }
  return gridStore().getGrid
})

const headerComponentParams = computed(() => {
  const definitions = grid.value?.columnsDefinition()
  return definitions?.find((col) => col.index === Number(colId?.value))
    ?.headerComponentParams
})

const isStatic = computed(
  () => props.params.static || props.params?.colIndex < 0,
)
const isFrequencyEnabled = computed(() => props?.params?.frequency?.enabled)
const isValidControlStyle = computed(
  () => headerComponentParams?.value?.columnState === ColumnState.ok,
)
const isNOKControlStyle = computed(
  () => headerComponentParams?.value?.columnState === ColumnState.ko,
)
const isChecked = computed(() => {
  const selectedCols = gridStore().getSelectedColumnsIndex as number[]
  return selectedCols?.findIndex((col) => col === props.params?.colIndex) > -1
})
const checkTime = computed(() => {
  return headerComponentParams.value?.frequencyTime
})

const inWorkplace = computed(() => {
  return grid.value?.isWorkplaceDoc
})
const alertClicked = () => {
  alertDrawerStore().setAlert(props.params.alert)
}

const onChecked = () => {
  gridStore().setSelectedColumns(props.params?.colIndex)
  gridStore().setSelectedColumnName(props?.params?.displayName)
}
</script>

<template>
  <div
    data-cy="custom-header"
    :class="[`_data-cy-column-${colId}`]"
    class="relative flex h-full w-full justify-center"
  >
    <div
      class="cell-wrapper border-r-none absolute left-0 top-0 flex h-8 w-full items-end bg-slate-100 font-medium"
      :class="{ 'bg-slate-100': !inWorkplace, 'bg-white': inWorkplace }"
    >
      <div
        v-if="isFrequencyEnabled && colId !== 'name'"
        class="flex-center flex gap-2 rounded-t-md bg-[#FAF0CC] px-2 py-1"
      >
        <component
          :is="Icons.TIME"
          class="text-sm"
        />
        <span class="text-sm"> {{ checkTime }} </span>
      </div>
      <div
        v-if="params.alerts?.length"
        v-can:read-alert
        class="flex-center flex cursor-pointer gap-2 rounded-t-md bg-[#FBDCE5] px-2 py-1 text-primary"
        @click="alertClicked"
      >
        <component
          :is="Icons.WARNING_ALT"
          class="text-sm"
        />
        <p class="text-sm tracking-wide">
          {{ $t('report.see_alert') }}
        </p>
      </div>
    </div>
    <div
      class="cell-bottom relative mt-8 flex w-full border-gray-200"
      :class="{
        'justify-start pl-4': colId === 'name',
        'justify-center border-l': !['name', 'file'].includes(colId),
      }"
    >
      <div
        v-if="displayCheckboxes && !isStatic"
        class="absolute -right-3 top-2"
      >
        <label
          data-cy="select-column"
          class="input-container"
        >
          <input
            v-model="isChecked"
            :value="isChecked"
            type="checkbox"
            @change="onChecked"
          />
          <span class="checkmark" />
        </label>
      </div>
      <div
        v-if="headerComponentParams?.columnState && !isStatic"
        class="mr-4 flex-shrink-0 self-center"
      >
        <component
          :is="Icons.CHECKMARK_OUTLINE"
          v-if="headerComponentParams?.columnState === ColumnState.ok"
          class="h-8 w-8 text-green-600"
        />

        <component
          :is="Icons.DASHED_OUTLINE"
          v-else-if="
            headerComponentParams?.columnState === ColumnState.inProgress
          "
          class="h-8 w-8 text-duskBlue"
        />

        <component
          :is="Icons.CLOSE_OUTLINE"
          v-else-if="headerComponentParams?.columnState === ColumnState.ko"
          class="h-8 w-8 text-red-600"
        />
      </div>
      <div class="space-y-2 self-center">
        <div
          class="text-left text-sm font-bold"
          data-cy="grid-header-name"
        >
          {{ props.params.displayName }}
        </div>
        <div
          v-if="lastDate && startDate"
          class="align-center flex flex-col text-sm"
        >
          <span
            :class="{
              'text-[#49AA19]': isValidControlStyle,
              'text-[#000]': isNOKControlStyle,
            }"
            class="align-left self-center text-left font-normal text-[#757575]"
            v-text="startDate"
          />
          <span
            :class="{
              'text-[#49AA19]': isValidControlStyle,
              'text-[#000]': isNOKControlStyle,
            }"
            class="align-left self-center text-left font-normal text-[#757575]"
            v-text="lastDate"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.grid-header-content {
  margin-left: 35px;
}

.input-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.input-container input {
  display: none;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: #fff;

  border: 1px solid #757575;
  border-radius: 14px;

  display: flex;
  align-items: center;
  justify-content: center;
}

/* On mouse-over, add a grey background color */
.input-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.input-container input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  display: none;
}

/* Show the checkmark when checked */
.input-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.input-container .checkmark:after {
  width: 8px;
  height: 8px;
  border: 1px solid #2196f3;
  background-color: #2196f3;
  border-radius: 50%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ag-header {
  height: 120px !important;
  border-bottom: none;
}

.ag-theme-alpine .ag-header-cell {
  height: 100% !important;
  background-color: #fafafa;
}

.ag-body-horizontal-scroll {
  @apply border-l border-r border-gray-200;
}
.ag-horizontal-left-spacer {
  border-right: 1px solid rgb(229 231 235) !important;
}

.ag-horizontal-right-spacer {
  display: none;
}

.ag-header-viewport {
  .ag-header-cell {
    .cell-bottom {
      @apply border-t border-gray-200;
    }
  }
  &::before {
    @apply border-r border-t border-gray-200 bg-[#FAFAFA];
    content: '';
    position: absolute;
    top: 32px;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.ag-pinned-left-header {
  .ag-header-cell {
    &:first-child {
      .cell-bottom {
        border-top-left-radius: 8px;
      }
    }
    .cell-bottom {
      @apply border-t border-gray-200;
    }
  }
}

.ag-header-viewport {
  @apply bg-slate-100;
}

.ag-header-row {
  height: inherit !important;
}

.ag-header-cell {
  border: none !important;
  // border-right: 1px solid #DEE2EA !important;

  &.header {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.ag-pinned-left-header {
  width: 500;
  border-right: none !important;

  .ag-header-cell {
    border-right: none !important;
  }
}
</style>

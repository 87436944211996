import Cell from './Cell'
import type { JInputData } from '~/models/report/jInputData'
import { type JStep, StepType } from '~/models/documents/jStep'
import { formatDate } from '~/utils/formatter'
import { usersStore } from '~/store/users'

export class CheckboxCell extends Cell {
  constructor(
    rowIndex: number,
    colIndex: number,
    inputs: JInputData[],
    step: JStep,
    isHistory: boolean,
    onExport: false,
    report_id: null,
  ) {
    if (step?.type !== StepType.Checkbox) throw new Error('Type mismatch')
    super(rowIndex, colIndex, inputs, step, isHistory, onExport, report_id)
    this._isActivated =
      step.last_sampling_areas[colIndex] || this.latestValue !== null
  }

  get latestValue(): string | null {
    const value = super.latestValue
    return value || null
  }

  parseAnswer(): string {
    const answer: JInputData | null =
      this.restoreValue ?? this.justifyKoValue ?? this.getLatestInputData()
    return answer?.value ? 'Valide' : 'Non valide'
  }

  getHistoryTooltip(): string {
    return this.getHistory()
      .filter((value, index) => index > 0)
      .map((history) => {
        return `${this.getHistoryLineText(history)} \n`
      })
      .join(' ')
  }

  getHistoryHTML(): string {
    return this.getHistory()
      .filter((value, index) => index > 0)
      .map((history) => {
        return `${this.getHistoryLineText(history)} <br>`
      })
      .join(' ')
  }

  getHistoryLineText(historicValue: JInputData): string {
    return `${historicValue.value ? 'checked' : 'unchecked'} ${formatDate(historicValue.update_date)}  ${usersStore().findUser(historicValue.updated_by, { full: true })}`
  }
}

export default CheckboxCell

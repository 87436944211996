import { defineStore } from 'pinia'

import API from '~/helpers/ApiHelper'

export const apiStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: 'api',
  // a function that returns a fresh state
  state: () => ({
    api: API,
  }),

  // optional getters
  getters: {
    getApiClient() {
      return this.api
    },
  },
  // optional actions
  actions: {},
})

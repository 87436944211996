<script setup lang="ts">
import { tCore } from '~/services/trpc'
import { siteStore } from '~/store/site'

const { t } = useI18n()
const site = siteStore().site
const isLoading = ref(false)
const error = ref('')

const list = [
  {
    name: t('simulator.derivation_data'),
    button: {
      text: t('simulator.generate'),
      action: async () => {
        try {
          error.value = ''
          isLoading.value = true
          await tCore.simulator.generateDerivationData.query()
        } catch (e) {
          console.log(e)
          error.value = e.message
        } finally {
          isLoading.value = false
        }
      },
    },
  },
]
</script>

<template>
  <div
    v-for="item in list"
    :key="item.name"
    class="flex items-center justify-between border-b border-gray-200 p-4"
  >
    <div>{{ item.name }}</div>
    <OButton
      v-if="site.flags.pilot_mode"
      :loading="isLoading"
      @click="
        (e) => {
          e.stopPropagation()
          item.button.action()
        }
      "
    >
      {{ item.button.text }}
    </OButton>
    <p
      v-else
      class="text-gray-400"
    >
      {{ $t('simulator.available_only_for_pilot_mode') }}
    </p>
  </div>
  <span class="pt-4 text-error-500">{{ error }}</span>
</template>

<style lang="scss"></style>

<script setup lang="ts">
import Camera from 'simple-vue-camera'
import { usePermission } from '@vueuse/core'
import { format } from 'date-fns'
import { Icons } from '~/models/Icon'
import { RoleGrid } from '~/models/documents/jReport'
import { gridStore } from '~/store/grid'
import { getLastUpdatedByOnCell } from '~/helpers/UtilsHelper'
import type Grid from '~/grid/Grid'
import { Carousel } from 'ant-design-vue'
import { siteStore } from '~/store/site'
import _ from 'lodash'
import DoubleCheck from './DoubleCheck.vue'

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
  isFromModal: {
    type: Boolean,
    default: false,
    required: false,
  },
})

const camera = ref<InstanceType<typeof Camera>>()
const carousel = ref(null) as any
const cameraAccess = usePermission('camera')

const state = reactive({
  displayCameraModal: false,
  isActivated: true,
  isPreviewPhoto: false,
  isPhotoUploading: false,
  hasVideoDevices: false,
  cameraStopped: false,
  modalCanceled: false,
  currentPhotoIndex: 0,
  addPicture: false,
  rowIndex:
    props.params?.node?.data?.index !== undefined
      ? props.params?.node.data?.index
      : props?.params?.rowIndex,
  colIndex:
    props?.params?.colDef?.index -
    props.params?.data?.details?.initialShiftIndex,
})

const openModal = () => {
  state.displayCameraModal = true
  state.modalCanceled = false
}

const closeModal = () => {
  state.displayCameraModal = false
  state.modalCanceled = true
  state.addPicture = false
}

const grid = computed<Grid>(() => {
  if (props.params?.colDef?.reportId) {
    return gridStore(String(props.params?.colDef?.reportId))?.getGrid
  }
  return gridStore().getGrid
})

const cell = computed(() => {
  return grid?.value
    ?.getCellByIndex(state.rowIndex, state.colIndex)
    ?.initializeActivationState(props.params)
})

const latestInputData = computed(() => {
  return cell?.value?.getLatestInputData()
})

const isEditable = computed(() => {
  return cell?.value?.isEditable(props.params)
})

const isActivated = computed(() => {
  return cell?.value?.isActivated ?? true
})

watch(
  () => state.displayCameraModal,
  async (val: boolean) => {
    if (val) {
      if (cameraAccess.value !== 'granted' && !state.isPreviewPhoto) {
        await navigator.mediaDevices.getUserMedia({ video: true })

        state.cameraStopped = false
        camera.value?.start()
      }

      if (cell?.value?.latestValue?.length) state.isPreviewPhoto = true
    }
  },
)

const modalTitleKey = computed(() => {
  if (cell?.value?.latestValue?.length) {
    return state.addPicture
      ? 'document.step_photo_add_picture'
      : 'document.step_photo_update_picture'
  }
  return 'document.step_photo_take_picture'
})

const modalInfos = computed(() => {
  if (cell?.value?.isHistory.value || state.isPreviewPhoto) {
    return {
      title: 'document.step_photo_preview_picture',
      saveBtnText: 'document.step_photo_take_picture_again',
      additionalBtnText: 'document.step_photo_add_picture',
    }
  }

  return {
    title: modalTitleKey.value,
    description:
      cell?.value?.latestValue?.length && !state.addPicture
        ? 'document.step_photo_update_picture_description'
        : 'document.step_photo_take_picture_description',
    saveBtnText: 'document.step_photo_take_picture',
    additionalBtnText:
      cell?.value?.latestValue?.length && state.isPreviewPhoto
        ? 'document.step_photo_add_picture'
        : null,
  }
})

const noCameraAccess = computed(() => {
  return cameraAccess.value !== 'granted' || !state.hasVideoDevices
})

const isPreview = computed(() => {
  return props.params.colDef.gridType === RoleGrid.preview
})

state.isPhotoUploading = false

const savePhoto = async () => {
  const latestValue = Array.isArray(cell.value?.latestValue)
    ? cell.value.latestValue
    : [cell.value?.latestValue]
  state.isPhotoUploading = true
  camera.value?.pause()

  try {
    const imageBlob = await camera.value?.snapshot()
    // @ts-expect-error
    const path = await cell?.value?.uploadImage(imageBlob)

    const value = [...latestValue]
    if (state.addPicture) {
      value.push(path)
    } else {
      value[state.currentPhotoIndex] = path
    }

    props.params?.node?.setDataValue(props.params?.column?.colId, value)
  } finally {
    state.isPhotoUploading = false
    state.addPicture = false
  }
}

const checkVideoDevices = async () => {
  const devices = (await camera.value?.devices(['videoinput'])) || []

  state.hasVideoDevices = devices && devices.length > 0
  if (!state.hasVideoDevices) camera.value?.stop()
}

const stopCamera = () => {
  state.cameraStopped = !state.modalCanceled
}

const removeAttachment = () => {
  props.params?.node?.setDataValue(props.params?.column?.colId, null)
}

const removePhoto = () => {
  const latestValue = Array.isArray(cell.value?.latestValue)
    ? cell.value.latestValue
    : [cell.value?.latestValue]

  state.currentPhotoIndex =
    carousel?.value?.innerSlider?.currentSlide ??
    cell?.value?.latestValue?.length ??
    0

  latestValue.splice(state.currentPhotoIndex, 1)
  const newValue = _.cloneDeep(latestValue)

  props.params?.node?.setDataValue(props.params?.column?.colId, newValue)
}

const newPhoto = (addPicture = false) => {
  state.isPreviewPhoto = false
  state.addPicture = addPicture

  state.currentPhotoIndex =
    carousel?.value?.innerSlider?.currentSlide ??
    cell?.value?.latestValue?.length ??
    0
}

const activateCell = () => {
  cell.value?.setIsActivated(isEditable.value)
}

const showHistoryIcon = siteStore().getFlag('history')

const history = computed(() => {
  return cell?.value?.getHistoryTooltip()
})
</script>

<template>
  <div v-if="!cell?.step?.hidden">
    <div
      v-if="
        (cell?.hasHistory && showHistoryIcon && !props.isFromModal) ||
        latestInputData?.reason
      "
      v-can.any="['read-report', 'create-report']"
      :tooltip="grid.onExport ? history : null"
      class="absolute right-1 top-1"
      data-cy="history-button"
      @click.stop="gridStore().setHistoryModalCell(cell ?? null)"
    >
      <component
        :is="Icons.RECENTLY_VIEWED"
        class="h-5 w-5 cursor-pointer text-grey-500"
      />
    </div>
    <div
      v-if="cell?.latestValue?.length"
      class="group"
    >
      <DoubleCheck
        :input-data="latestInputData"
        :cell="cell"
        :is-from-modal="false"
        :is-history="cell?.isHistory"
      />
      <div class="relative inline-block">
        <div class="relative">
          <component
            :is="Icons.GROUP_RESOURCE"
            v-if="cell?.latestValue?.length > 1"
            class="absolute bottom-0 left-0 right-0 top-0 z-50 h-5 w-5 p-0.5"
          />
          <img
            :src="cell?.latestValue[0]"
            class="mx-auto rounded-md object-cover"
            :class="`${props.params.data.description ? 'h-32 w-40' : 'h-fit w-fit'}`"
          />
        </div>
        <component
          :is="Icons.CLOSE"
          v-if="!cell?.isHistory && isEditable && !grid.onExport"
          class="absolute right-[-12px] top-[-14px] z-50 h-6 w-6 cursor-pointer rounded-full border border-black bg-white p-0.5 text-black"
          @click="removeAttachment"
        />
        <div
          v-if="
            props.params?.colDef?.answers?.some(
              (a) => a.row_id === props.params?.rowIndex,
            )
          "
          v-can.any="['read-report', 'create-report']"
          class="absolute bottom-[-8px] right-[-12px] z-50"
          data-cy="avatar-button"
        >
          <AvatarMultiple
            :user-ids="[getLastUpdatedByOnCell(props.params)]"
            :hide-full-name="!grid?.onExport"
            display-amount
            is-enable-modal
          />
        </div>
      </div>
      <div
        v-if="
          props.params?.colDef?.answers?.some(
            (a) => a.row_id === props.params?.rowIndex,
          )
        "
        v-can.any="['read-report', 'create-report']"
        class="absolute bottom-[-8px] right-[-12px] z-50 flex flex-col-reverse items-center pb-2"
        data-cy="avatar-button"
      >
        <AvatarMultiple
          :user-ids="[getLastUpdatedByOnCell(props.params)]"
          display-amount
          :hide-full-name="!grid?.onExport"
          is-enable-modal
        />
        <span
          v-if="latestInputData?.update_date && grid.onExport"
          class="text-center text-sm text-gray-600"
          >{{
            format(new Date(latestInputData?.update_date), 'dd/MM/yy - HH:mm')
          }}</span
        >
      </div>

      <div
        v-if="latestInputData?.updated_by"
        v-can.any="['read-report', 'create-report']"
        class="absolute bottom-0 z-50 flex flex-col-reverse items-center gap-2"
        :class="{ 'right-[-12px]': !grid.onExport, 'right-0': grid.onExport }"
        data-cy="avatar-button"
      >
        <AvatarMultiple
          :user-ids="[latestInputData?.updated_by]"
          :update-date="latestInputData?.update_date"
          :hide-full-name="!grid?.onExport"
          display-amount
          is-enable-modal
        />
        <span
          v-if="latestInputData?.update_date && grid.onExport"
          class="text-center text-sm text-gray-600"
          >{{
            format(new Date(latestInputData?.update_date), 'dd/MM/yy - HH:mm')
          }}</span
        >
      </div>
      <o-button
        v-if="!grid.onExport"
        alternate
        class="absolute left-1/2 hidden -translate-x-2/4 translate-y-0 group-hover:block"
        :class="`${props.params.data.description ? '-mt-20' : '-mt-16'}`"
        @click="openModal"
      >
        <component
          :is="cell?.isHistory ? Icons.VIEW : Icons.EDIT"
          class="h-4 w-4"
        />
      </o-button>
    </div>

    <div v-else-if="!cell?.latestValue?.length && isActivated">
      <o-button
        :is-disabled="isPreview || cell?.isHistory || !isEditable"
        alternate
        @click="openModal"
      >
        <component
          :is="Icons.CAMERA"
          class="self-center"
        />
        <span v-if="!grid.onExport">
          {{ $t('document.step_photo_take_picture') }}
        </span>
      </o-button>
    </div>

    <div
      v-else
      class="rounded bg-[#CBCCCD] px-2 py-1.5 text-[#FFFFFF]"
      @click="activateCell"
    >
      N/A
    </div>
  </div>
  <ModalDynamic
    v-model="state.displayCameraModal"
    :is-cross-close="isPreview || cell?.isHistory || !isEditable"
    :width="{ outer: 'w-[900px]', inner: 'w-full' }"
    :modal-title="modalInfos.title"
    :modal-description="modalInfos.description"
    :save-text="modalInfos.saveBtnText"
    :additional-button-text="modalInfos.additionalBtnText"
    :icon="Icons.CAMERA"
    top-modal
    :disable-save-btn="
      !state.hasVideoDevices &&
      (state.isPhotoUploading || !state.isPreviewPhoto || cell?.isHistory)
    "
    @save="state.isPreviewPhoto ? newPhoto() : savePhoto()"
    @additional-button="newPhoto(true)"
    @action-on-close="closeModal"
  >
    <template #body>
      <div
        class="h-full"
        :class="{ 'pt-8': modalInfos.description?.length }"
      >
        <Camera
          v-if="
            !state.isPreviewPhoto &&
            !cell?.isHistory &&
            cameraAccess === 'granted'
          "
          ref="camera"
          class="rounded-md"
          :resolution="{ width: 1920, height: 1080 }"
          autoplay
          @loading="checkVideoDevices"
          @stopped="stopCamera"
        />
        <div class="relative flex">
          <component
            :is="Icons.CLOSE"
            v-if="
              !cell?.isHistory &&
              isEditable &&
              !grid.onExport &&
              cell?.latestValue.length > 1 &&
              state.isPreviewPhoto
            "
            class="absolute right-[-12px] top-[-14px] z-50 h-6 w-6 cursor-pointer rounded-full border border-black bg-white p-0.5 text-black"
            @click="removePhoto"
          />
          <Carousel
            v-if="
              (cell?.isHistory || state.isPreviewPhoto) &&
              cell?.latestValue?.length
            "
            ref="carousel"
            arrows
            class="relative w-full"
            draggable
            swipe-to-slide
          >
            <template #prevArrow>
              <button class="text-black">&lt;</button>
            </template>
            <template #nextArrow>
              <button class="text-black">&gt;</button>
            </template>
            <div
              v-for="(src, index) in cell?.latestValue"
              :key="index"
              class="flex items-center justify-center"
            >
              <img
                :src="src"
                class="max-h-full max-w-full rounded-md object-contain"
                :alt="`Image ${index + 1}`"
              />
            </div>
          </Carousel>
        </div>

        <component
          :is="Icons.CAMERA_OFF"
          v-if="noCameraAccess && !cell?.isHistory && !state.isPreviewPhoto"
          class="mx-auto h-16 w-16"
        />
        <div
          v-if="
            state.cameraStopped &&
            !state.hasVideoDevices &&
            !state.isPreviewPhoto
          "
          class="p-6 text-center text-sm text-gray-600"
        >
          {{ $t('document.step_photo_no_devices_found') }}
        </div>
        <div
          v-if="!cell?.isHistory && noCameraAccess && !state.isPreviewPhoto"
          class="p-6 text-center text-sm text-gray-600"
        >
          {{ $t('document.step_photo_no_camera_access') }}
        </div>
      </div>
    </template>
  </ModalDynamic>
</template>

<style scoped>
.ant-carousel .slick-prev::after,
.ant-carousel .slick-next::after {
  content: '';
  display: inline-block;
  border: solid black;
  border-width: 0 3px 3px 0;
  padding: 3px;
}

.ant-carousel .slick-prev::after {
  transform: rotate(135deg);
}

.ant-carousel .slick-next::after {
  transform: rotate(-45deg);
}
</style>

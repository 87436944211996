import { JAction } from './JAction'

export enum PriorityStatus {
  LOW = 1,
  MEDIUM,
  HIGH,
  TBD = 99,
}

export enum ProjectStatus {
  NEW = 1,
  IN_PROGRESS,
  FINISHED,
}

export enum ProjectInfo {
  ASSIGNEES,
  DEADLINE,
  PROGRESS,
  PRIORITY,
  BUDGET,
  STATUS,
  DESCRIPTION,
  LOCALISATION,
}

export interface JProject {
  id: number
  name: string
  description: string
  assigneesIds: string[]
  // To be replaced by startAt: Date, endAt: Date on backend implementation
  deadline: Date
  priority: PriorityStatus
  budget: number
  status: ProjectStatus
  actions: JAction[]

  // this should be ids: number[], it's only 1 name for demo purposes
  workplaces: string
}

type NullableJProject<T> = { [P in keyof T]: T[P] | null }

export type NewJProject = NullableJProject<
  Omit<JProject, 'id' | 'status' | 'actions'>
> & {
  filesToUpload: string[]
}

import { defineStore } from 'pinia'
import { getAllSettings } from '~/controllers/settings'
import { ReportStatus } from '~/models/documents/jReport'
import type { Settings } from '~/models/settings/settings'
import { SettingsType } from '~/models/settings/settings'

export const settingsStore = defineStore({
  id: 'settings',
  state: () => ({
    settings: [] as Settings[],
  }),
  actions: {
    async loadSettings(): Promise<any> {
      const settings = await getAllSettings()
      this.settings = settings
    },
    filterSettings(settingType: any, withDeleted = false) {
      let settings: any = this.settings
      if (!withDeleted) settings = settings.filter((u) => !u.deleted_at)

      settings = settings.filter((u: any) => {
        return settingType === u.type
      })
      return settings
    },
    findSettingById(id: string) {
      let settings: any = this.settings
      settings = settings.find((u: any) => {
        return id === u.id
      })
      return settings
    },
    addSetting(setting: any) {
      this.settings.push(setting)
    },
    getStatuses() {
      const statuses: any = this.settings.filter(
        (u: any) => u.type === SettingsType.report_status,
      )
      const builtInStatuses = [
        { value: ReportStatus.new, color: '' },
        { value: ReportStatus.in_progress, color: '' },
        { value: ReportStatus.finished, color: '' },
      ]

      return [...builtInStatuses, ...statuses]
    },
    getSavedAlertsMessages() {
      return this.filterSettings(SettingsType.template_alerts_message).map(
        (e: any) => ({
          label: e.value, // e.message
          value: e.value, // e.message
        }),
      )
    },
  },
})

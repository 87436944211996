import api from '~/helpers/ApiHelper'

class RecordDataController {
  async getRecordData(payload) {
    const recordData = await api.getRecordData(payload)

    return recordData.map((e) => ({
      ...e,
      measured_at: new Date(e.measured_at),
    }))
  }

  async checkNCValues(payload) {
    return await api.checkNCValues(payload)
  }

  async last24HoursDevices(payload = {}) {
    return await api.last24HoursDevices(payload)
  }
}

const recordDataController = new RecordDataController()

export default recordDataController

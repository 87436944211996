<script setup lang="ts">
import type { RouterOutput } from '@mesx-server/trpc/router'
import { useFetchAllMachines } from '~/composables/mesx/requests/machine'
import {
  useFetchSimulatorTagStatus,
  useMutateSimulatorToggleCycleTag,
  useMutateSimulatorToggleStatusTag,
  useMutateSimulatorToggleScrapTag,
} from '~/composables/mesx/requests/simulator'
import OButton from '~/components/UI/OButton.vue'
import SimulatorGenerateDataButton from './SimulatorGenerateDataButton.vue'
import SimulatorGenerateProcessDataButton from './SimulatorGenerateProcessDataButton.vue'
const { tag } = defineProps<{
  tag: RouterOutput['fetchAllTags'][number]
}>()

const cycleTime = ref<string>('1000')
const scrapTime = ref<string>('1000')

const { data: isProducing, refetch } = useFetchSimulatorTagStatus({
  tagId: ref(tag.id),
})

const { data: xMachines } = useFetchAllMachines()
const machineIds = ref<string[]>([])
watch(
  () => xMachines.value,
  (newVal) => {
    machineIds.value = newVal.map((e) => e.id).flat()
  },
  {
    immediate: true,
    deep: true,
  },
)

const { mutateAsync: toggleCycleTag, isLoading: toggleCycleTagLoading } =
  useMutateSimulatorToggleCycleTag()
const { mutateAsync: toggleScrapTag, isLoading: toggleScrapTagLoading } =
  useMutateSimulatorToggleScrapTag()
const { mutateAsync: toggleStatusTag, isLoading: toggleStatusTagLoading } =
  useMutateSimulatorToggleStatusTag()

const toggleProduction = () => {
  if (tag.properties.consumerType === 'diCycle') {
    toggleCycleTag({
      tagId: tag.id,
      cycleTime: parseInt(cycleTime.value),
    }).then(() => {
      refetch()
    })
  } else if (tag.properties.consumerType === 'diScrap') {
    toggleScrapTag({
      tagId: tag.id,
      cycleTime: parseInt(scrapTime.value),
    }).then(() => {
      refetch()
    })
  } else {
    toggleStatusTag({
      tagId: tag.id,
    }).then(() => {
      refetch()
    })
  }
}
</script>

<template>
  <tr class="">
    <!-- Tag: -->
    <td>
      <span
        class="inline-flex h-6 w-max cursor-default items-center rounded bg-slate-100 px-2 text-sm font-medium text-slate-400"
        >{{ tag.clientId }}</span
      >
    </td>
    <td>
      <span
        class="inline-flex h-6 w-max cursor-default items-center rounded bg-[#F0F2FF] px-2 text-sm font-medium text-[#5D669E]"
        >{{ tag.tagId }}</span
      >
    </td>
    <td>
      <span
        class="inline-flex h-6 w-max cursor-default items-center rounded bg-[#F0F2FF] px-2 text-sm font-medium text-[#5D669E]"
        >{{ tag.properties.consumerType }}</span
      >
    </td>
    <td>
      <span
        v-for="machineToTag in tag.machineToTag"
        :key="machineToTag.machine.id"
        class="inline-flex h-6 w-max cursor-default items-center rounded bg-[#F0F2FF] px-2 text-sm font-medium text-[#5D669E]"
        >{{ machineToTag.machine.label }}</span
      >
    </td>
    <td>
      <input
        v-if="tag.properties.consumerType === 'diCycle'"
        v-model="cycleTime"
        :disabled="
          !tag.id || isProducing || tag.properties.consumerType !== 'diCycle'
        "
        class="w-16 rounded-sm border border-gray-300 bg-gray-50 p-1 text-center text-xs text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
      />
      <input
        v-if="tag.properties.consumerType === 'diScrap'"
        v-model="scrapTime"
        :disabled="
          !tag.id || isProducing || tag.properties.consumerType !== 'diScrap'
        "
        class="w-16 rounded-sm border border-gray-300 bg-gray-50 p-1 text-center text-xs text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
      />
    </td>
    <td class="pe-4">
      <SimulatorGenerateDataButton
        v-if="tag.properties.consumerType === 'diCycle'"
        :tag-id="tag.id"
        :machines="tag.machineToTag"
      />
      <SimulatorGenerateScrapDataButton
        v-if="tag.properties.consumerType === 'diScrap'"
        :tag-id="tag.id"
        :machines="tag.machineToTag"
      />
      <SimulatorGenerateProcessDataButton
        v-if="tag.properties.consumerType == 'diProcessDigital'"
        :tag-id="tag.id"
        :machines="tag.machineToTag"
      />
    </td>
    <td>
      <div class="flex items-center justify-center">
        <OButton
          class=""
          :is-disabled="
            tag.properties.consumerType === 'diProcessDigital' ||
            tag.properties.consumerType === 'diProcessAnalog'
          "
          :loading="
            toggleCycleTagLoading ||
            toggleScrapTagLoading ||
            toggleStatusTagLoading
          "
          @click="toggleProduction"
        >
          {{ isProducing ? 'Stop' : 'Start' }}
        </OButton>
        <div
          class="ml-4 h-6 w-6 rounded-full"
          :class="[isProducing ? 'bg-green-500' : 'bg-red-500']"
        />
      </div>
    </td>
  </tr>
</template>

<style lang="scss"></style>

<script setup lang="ts">
const props = defineProps({
  full: {
    type: Boolean,
    required: false,
    default: false,
  },
  bgCssColor: {
    type: String,
    required: false,
    default: '',
  },
  textCssColor: {
    type: String,
    required: false,
    default: 'white',
  },
  borderCssColor: {
    type: String,
    required: false,
    default: 'primary',
  },
  isDisabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
  alternate: {
    type: Boolean,
    required: false,
    default: false,
  },
  height: {
    type: String,
    required: false,
    default: '8',
  },
  leftIcon: {
    type: Object,
    required: false,
    default: null,
  },
  buttonColor: {
    type: String,
    required: false,
    default: null,
  },
  rounded: {
    type: String,
    default: '',
  },
  big: {
    type: Boolean,
    required: false,
    default: false,
  },
  btnClass: {
    type: String,
    required: false,
    default: '',
  },
  customIcon: {
    type: Boolean,
    required: false,
    default: false,
  },
  dataCy: {
    type: String,
    required: false,
    default: '',
  },
  type: {
    type: String,
    required: false,
    default: 'button',
  },
})

const emit = defineEmits<{
  (e: 'btnWidth', val: number): void
}>()

const styleController = () => {
  const defaultBorder = props.buttonColor
    ? `border-${props.buttonColor}`
    : `border-primary ${props.loading && 'border-transparent'}`
  const alternateBorder = `border border-primary ${props.loading && 'border-transparent'}`
  const bg = props.buttonColor
    ? `bg-${props.buttonColor} hover:bg-${props.buttonColor}/90`
    : 'bg-primary hover:bg-primary/90'
  if (props.alternate)
    return `bg-secondary !text-primary hover:bg-tertiary ${alternateBorder}`

  return props.bgCssColor.length
    ? `${props.bgCssColor} text-${props.textCssColor} border ${props.borderCssColor}`
    : `${bg} text-${props.textCssColor} border ${defaultBorder}`
}

const slots = useSlots()
const btnWrapper = ref(null)

watch(
  () => btnWrapper,
  (newVal) => {
    if (newVal.value !== null) emit('btnWidth', newVal.value.clientWidth)
  },
  {
    deep: true,
    immediate: true,
  },
)
</script>

<template>
  <div
    ref="btnWrapper"
    class="flex"
    :class="{ 'read-only': isDisabled, 'w-full justify-center': props.full }"
  >
    <!--
      :class="`disabled:bg-[#dddddd] disabled:border-[#dddddd] disabled:text-slate-500 w-max inline-flex text-base items-center p-2 h-8 font-normal ${props.rounded ? props.rounded : 'rounded'} ${styleController()}`"

-->
    <button
      :data-cy="props.dataCy"
      :type="props.type"
      :class="`${btnClass} inline-flex w-max items-center text-base disabled:bg-[#dddddd] disabled:text-slate-500 ${props.big ? 'h-10 !rounded-lg px-4' : 'h-8 p-2'}  font-normal ${props.rounded ? props.rounded : 'rounded'} ${styleController()} ${props.full ? '!w-full justify-center' : ''}`"
      :disabled="isDisabled || props.loading"
    >
      <div
        v-if="props.loading"
        data-cy="btn-loader"
        style="border-top-color: transparent"
        class="h-4 w-4 animate-spin rounded-full border-2 border-solid"
        :class="[
          `border-${props.textCssColor}`,
          { '!border-t-transparent': props.alternate },
        ]"
      />
      <div
        v-else
        class="flex content-center items-center justify-between"
        :class="{ 'gap-2': !props.big, 'gap-3': props.big }"
      >
        <component
          :is="props.leftIcon?.name"
          v-if="props.leftIcon?.name?.name?.length || customIcon"
          class="h-5 w-5"
          :class="[props.leftIcon.class]"
        />
        <slot />
      </div>
    </button>
  </div>
</template>

<script setup lang="ts">
const toastState = reactive({
  show: false,
  status: 'success',
  title: '',
  content: '',
  displayInterval: 5000,
})

provide('toast', toastState)

const resetToast = (isDisplay: boolean) => {
  toastState.show = isDisplay
}
</script>

<template>
  <main
    class="text-center text-gray-700 dark:text-gray-200"
    style="height: calc(100vh - 200px)"
  >
    <o-toast
      v-bind="toastState"
      @toast-display="resetToast"
    />
    <router-view />

    <div class="mx-auto mt-5 text-center text-sm opacity-25" />
  </main>
</template>

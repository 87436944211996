<script setup lang="ts">
import { Icons } from '~/models/Icon'
import { RoleGrid } from '~/models/documents/jReport'
import { gridStore } from '~/store/grid'
import * as math from 'mathjs'
import { siteStore } from '~/store/site'
import { isEqual, isEmpty } from 'lodash'
import { settingsStore } from '~/store/settings'
import { SettingsType } from '~/models/settings/settings'
import vSelect from 'vue-select'
import type Grid from '~/grid/Grid'
import { format } from 'date-fns'
import CalculatorCell from '~/grid/CalculatorCell'
import DoubleCheck from './DoubleCheck.vue'

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
  isFromModal: {
    type: Boolean,
    default: false,
    required: false,
  },
})

const state = reactive({
  displayCalculatorModal: false,
  isActivated: true,
  rowIndex:
    props.params?.node?.data?.index !== undefined
      ? props.params?.node.data?.index
      : props?.params?.rowIndex,
  colIndex:
    props?.params?.colDef?.index -
    props.params?.data?.details?.initialShiftIndex,
  reason: '',
  isSubmited: false,
})

const openModal = () => {
  gridStore().setHasOpenedModal(true)
  state.displayCalculatorModal = true
}

const closeModal = () => {
  state.displayCalculatorModal = false
  gridStore().setHasOpenedModal(false)
}

const grid = computed<Grid>(() => {
  if (props.params?.colDef?.reportId) {
    return gridStore(String(props.params?.colDef?.reportId))?.getGrid
  }
  return gridStore().getGrid
})

const cell = computed((): CalculatorCell => {
  return grid?.value
    ?.getCellByIndex(state.rowIndex, state.colIndex)
    ?.initializeActivationState(props.params) as CalculatorCell
})

const reasons = computed(() => {
  const reasonsList = settingsStore().filterSettings(
    SettingsType.template_input_modification_message,
  )

  return reasonsList.map((e: any) => ({
    label: e.value,
    value: e.id,
  }))
})

const showHistoryIcon = siteStore().getFlag('history')

const calculTokens = computed(() => {
  return cell.value?.calculTokens
})

const isSaveDisabled = computed(() => {
  const newContext = calculTokens.value
    ?.filter((segment) => segment.isInput || segment.type === 'Step')
    ?.map((e) => e.value)

  const currentContext = latestInputData?.value?.context?.calculContext

  return !calculatedResult.value || isEqual(newContext, currentContext)
})

const latestInputData = computed(() => {
  return cell?.value?.getLatestInputData()
})

const isEditable = computed(() => {
  return cell?.value?.isEditable(props.params)
})

const isActivated = computed(() => {
  return cell?.value?.isActivated ?? true
})

const isPreview = computed(() => {
  return props.params.colDef.gridType === RoleGrid.preview
})

const isJustification = computed(() => {
  return !isEditable.value && cell?.value?.latestValue
})

const saveCalcul = async () => {
  if (!state.reason && isJustification.value) {
    state.isSubmited = true
    return
  }
  const context = [] as string[]

  calculTokens.value?.forEach((token) => {
    if (token.isInput || token.type === 'Step') context.push(token.value)
  })
  const calcul = {
    result: calculatedResult.value,
    calculContext: context,
    reason: state.reason?.label || null,
  }

  state.isSubmited = false
  props.params?.node?.setDataValue(props.params?.column?.colId, calcul)
}

const calculatedResult = computed(() => {
  try {
    let expression = ''

    calculTokens.value?.forEach((token) => {
      expression += token.value
    })

    expression = expression.replace(/Moy/g, 'mean')

    const result = math.evaluate(
      expression?.toLowerCase()?.replace(/,/g, '.')?.replace(/;/g, ','),
    )
    if (result === Infinity || result === -Infinity) return false

    if (Number.isInteger(result)) {
      return result.toString()
    }

    return result.toFixed(3).toString()
  } catch (error) {
    return false
  }
})

const activateCell = () => {
  cell.value?.setIsActivated(isEditable.value)
}

const handleSearch = (searchText: string) => {
  state.reason =
    searchText.length || state.reason.length === 1 ? searchText : state.reason
}

const history = computed(() => {
  return cell?.value?.getHistoryTooltip()
})
</script>

<template>
  <div v-if="!cell?.step?.hidden">
    <div
      v-if="cell?.latestValue"
      class="flex"
    >
      <span v-if="!grid.onPdf"> {{ cell?.latestValue }}</span>
      <span v-else>
        {{ cell.replaceComputePlaceholders() }}
      </span>
      <DoubleCheck
        :input-data="latestInputData"
        :cell="cell"
        :is-from-modal="false"
        :is-history="cell?.isHistory"
      />
      <div
        v-if="
          (cell?.hasHistory || latestInputData?.reason) &&
          showHistoryIcon &&
          !props.isFromModal
        "
        v-can.any="['read-report', 'create-report']"
        :tooltip="grid?.onExport ? history : null"
        class="absolute right-1 top-1"
        data-cy="history-button"
        @click.stop="gridStore().setHistoryModalCell(cell ?? null)"
      >
        <component
          :is="Icons.RECENTLY_VIEWED"
          class="h-5 w-5 cursor-pointer text-grey-500"
        />
      </div>
      <div
        v-if="!cell?.isHistory"
        v-can.any="['read-report', 'create-report']"
        class="absolute bottom-1 left-1"
        data-cy="edit-calcul-button"
        @click.stop="openModal"
      >
        <component
          :is="
            calculatedResult === latestInputData?.value
              ? Icons.EDIT
              : Icons.WARNING_ALT
          "
          class="h-5 w-5 cursor-pointer text-grey-500"
        />
      </div>
      <div
        v-if="latestInputData?.updated_by"
        v-can.any="['read-report', 'create-report']"
        class="absolute bottom-1 right-1 flex flex-col-reverse items-center pb-2"
        data-cy="avatar-button"
      >
        <AvatarMultiple
          :user-ids="[latestInputData?.updated_by]"
          :update-date="latestInputData?.update_date"
          :hide-full-name="!grid?.onExport"
          display-amount
          is-enable-modal
        />
        <span
          v-if="latestInputData?.update_date && grid?.onExport"
          class="text-center text-sm text-gray-600"
          >{{
            format(new Date(latestInputData?.update_date), 'dd/MM/yy - HH:mm')
          }}
        </span>
      </div>
    </div>

    <div
      v-else-if="!cell?.latestValue && isActivated"
      class="flex flex-row rounded-md border text-info-600"
    >
      <o-button
        :is-disabled="isPreview || cell?.isHistory || !isEditable"
        alternate
        @click="openModal"
      >
        <component
          :is="Icons.CALCULATE"
          class="self-center"
        />
        <span v-if="!grid?.onExport">
          {{ $t('step.fill_calcul_btn') }}
        </span>
      </o-button>
    </div>

    <div
      v-else
      class="rounded bg-[#CBCCCD] px-2 py-1.5 text-[#FFFFFF]"
      @click="activateCell"
    >
      N/A
    </div>
  </div>
  <ModalDynamic
    v-model="state.displayCalculatorModal"
    :is-cross-close="isPreview || cell?.isHistory || !isEditable"
    :width="{ outer: 'w-[900px]', inner: 'w-full' }"
    modal-title=""
    top-modal
    :more-title="`${cell?.step?.name} #${state.colIndex + 1}`"
    :save-text="'global.validate'"
    :overflow-style="'overflow-visible'"
    :is-display-icon="false"
    :disable-save-btn="isSaveDisabled"
    @save="saveCalcul()"
    @action-on-close="closeModal"
  >
    <template #body>
      <div class="flex flex-col overflow-visible border-t">
        <div
          class="mt-8 flex w-full flex-col gap-4 rounded-md border border-info-300"
        >
          <div class="overflow-x-auto">
            <div class="flex flex-col gap-4 p-4">
              <span class="text-info-600">
                {{ `${$t('step.fill_calcul_title')} :` }}
              </span>
              <div class="flex flex-row items-center">
                <div
                  v-for="(segment, index) in calculTokens"
                  :key="index"
                  class="flex flex-row items-center gap-2"
                >
                  <div
                    class="relative flex flex-col items-center justify-center"
                  >
                    <span
                      v-if="segment.type === 'Step'"
                      class="absolute left-0 top-9 text-xxs"
                    >
                      {{ segment.stepName?.toLowerCase() }}</span
                    >
                    <span
                      v-if="!segment.isInput && segment.type !== 'Step'"
                      class="rounded-lg p-2"
                    >
                      {{ segment.value?.toUpperCase() }}
                    </span>
                    <FormKit
                      v-else
                      v-model="segment.value"
                      :disabled="segment.type === 'Step'"
                      type="number"
                      :classes="{
                        outer: 'w-20 z-50',
                        inner: 'flex ',
                        messages: 'hidden',
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex w-full flex-col gap-2 rounded-b-md border-t border-info-200 bg-grey-150 p-4"
          >
            <span class="text-sm text-info-600">
              {{ $t('global.result') }}
            </span>
            <span
              v-if="calculatedResult"
              class="text-3xl"
            >
              {{ calculatedResult }}
            </span>
          </div>
        </div>
        <div
          v-if="isJustification"
          class="mt-4 flex w-full flex-col gap-2"
        >
          <p class="text-base text-primary">
            {{ $t('report.justification') }}
          </p>
          <v-select
            v-model="state.reason"
            data-cy="reason"
            class="user-select z-50 h-full w-full cursor-text"
            taggable
            :options="reasons"
            :reduce="(option) => option || option.value"
            :placeholder="$t('report.precise_reasons')"
            data-reason="reason"
            :class="{
              error: state.isSubmited && isEmpty(state.reason),
            }"
            @search="handleSearch"
          >
            <template #selected-option="{ label }">
              <div class="inline-flex">
                <div class="h-6 rounded-md px-1 text-sm leading-6">
                  <span> {{ label }} </span>
                </div>
              </div>
            </template>
          </v-select>
        </div>
      </div>
    </template>
  </ModalDynamic>
</template>

<style>
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
  --vs-search-input-placeholder-color: grey;
}
</style>

<style lang="scss">
.user-select {
  .vs__selected-options {
    color: gray !important;
  }

  .vs__dropdown-menu {
    position: absolute;
    z-index: 100000 !important;
  }

  &.error > div:first-child {
    @apply border border-solid border-red-500;
  }
}
</style>

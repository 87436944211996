import Cell from './Cell'
import type { JInputData } from '~/models/report/jInputData'
import { type JStep, StepType } from '~/models/documents/jStep'
import StorageHelper from '~/helpers/storageHelper'
import { usersStore } from '~/store/users'
import { formatDate } from '~/utils/formatter'

export class PhotoCell extends Cell {
  private storageHelper: StorageHelper

  constructor(
    rowIndex: number,
    colIndex: number,
    inputs: JInputData[],
    step: JStep,
    isHistory: boolean,
    onExport: false,
    report_id: null,
  ) {
    if (step.type !== StepType.Photo) throw new Error('Type mismatch')

    super(rowIndex, colIndex, inputs, step, isHistory, onExport, report_id)
    this.storageHelper = new StorageHelper()
  }

  get latestValue(): string[] {
    const value = super.latestValue

    if (Array.isArray(value)) {
      return value
    } else if (value) {
      return [value]
    } else {
      return []
    }
  }

  getHistoryHTML(): string {
    return this.getHistory()
      .filter((value, index) => index > 0)
      .map((history) => {
        return `${this.getHistoryLineText(history)} <br>`
      })
      .join(' ')
  }

  getHistoryLineText(historicValue: JInputData): string {
    if (Array.isArray(historicValue.value)) {
      return historicValue.value
        .map(
          (item) =>
            `<img src="${item}" /> ${formatDate(historicValue.update_date)} ${usersStore().findUser(historicValue.updated_by, { full: true })}`,
        )
        .join(', ')
    } else {
      // If value is not an array, generate HTML for the single value
      return `<img src="${historicValue.value}" /> ${formatDate(historicValue.update_date)} ${usersStore().findUser(historicValue.updated_by, { full: true })}`
    }
  }

  async uploadImage(imageBlob: Blob): Promise<string> {
    const blobId = URL.createObjectURL(imageBlob).split('/').at(-1)
    const fileName = `Screenshot_${this._step.id}_${blobId}`
    const path = await this.storageHelper.uploadFile(
      `clients/${usersStore().user.client_id}/${fileName}`,
      imageBlob,
      fileName,
    )
    return path
  }
}

export default PhotoCell

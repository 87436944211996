import type { StatusModel, StatusRawModel } from '~/common/models/status'
import api from '~/helpers/ApiHelper'

class StatusController {
  async getStatuses(): Promise<StatusModel[]> {
    const statuses = await api.getStatuses()

    return statuses
  }

  async createStatus(status: StatusRawModel) {
    try {
      await api.createStatus(status)
    } catch (e) {
      console.error('Cannot create status', e)
    }
  }

  async updateStatus(status) {
    try {
      await api.updateStatus(status)
    } catch (e) {
      console.error('Cannot update status', e)
    }
  }

  async deleteStatus(statusId: number) {
    try {
      await api.deleteStatus(statusId)
    } catch (e) {
      console.error('Cannot delete status', e)
    }
  }
}

const statusController = new StatusController()

export default statusController

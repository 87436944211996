<script setup lang="ts">
import { ref } from 'vue'
import { Icons } from '~/models/Icon'

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
})

const clickedImage = ref<number | null>(null)

const handleImageClick = (index: number) => {
  clickedImage.value = index
}

const closeImage = () => {
  clickedImage.value = null
}
</script>

<template>
  <div
    v-for="(src, index) in params.answer?.value"
    :key="index"
    class="flex items-center justify-center"
  >
    <img
      :src="src"
      class="max-h-full max-w-full cursor-pointer rounded-md object-contain"
      :alt="`Image ${index + 1}`"
      @click="handleImageClick(index)"
    />
  </div>

  <div
    v-if="clickedImage !== null"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
  >
    <div
      class="absolute right-4 top-4 z-50 cursor-pointer"
      @click="closeImage"
    >
      <component
        :is="Icons.CLOSE"
        class="text-primary"
      />
    </div>

    <img
      :src="params.answer?.value[clickedImage]"
      class="max-h-full max-w-full rounded-md object-contain"
      :alt="`Image ${clickedImage + 1}`"
    />
  </div>
</template>

<style scoped>
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.z-50 {
  z-index: 50;
}

.bg-opacity-75 {
  background-color: rgba(0, 0, 0, 0.75);
}

.absolute {
  position: absolute;
}

.top-4 {
  top: 1rem;
}

.right-4 {
  right: 1rem;
}
</style>

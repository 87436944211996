import axios from 'axios'
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from 'firebase/storage'
import loggerHelper from '../LoggerHelper'
import { fb_storage } from '~/services/firebase'

export default class StorageHelper {
  storage: any
  logInfo?: boolean

  constructor() {
    this.storage = fb_storage
  }

  async uploadFile(
    pathInStorage: string,
    file: any,
    filename: string,
    contentType = '',
  ) {
    if (contentType === '') {
      const filetype = filename
        .split('.')
        [filename.split('.').length - 1].toLowerCase()
      switch (filetype) {
        case 'pdf':
          contentType = 'application/pdf'
          break
        case 'xls':
          contentType = 'application/vnd.ms-excel'
          break
        case 'xlsx':
          contentType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          break
        case 'csv':
          contentType = 'text/csv'
          break
        case 'doc':
          contentType = 'application/msword'
          break
        case 'docx':
          contentType =
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          break
        case 'png':
          contentType = 'image/png'
          break
        case 'jpg':
          contentType = 'image/jpeg'
          break
        case 'jpeg':
          contentType = 'image/jpeg'
          break
        case 'ics':
          contentType = 'text/calendar'
          break
        default:
          contentType = 'application/octet-stream'
      }
    }
    const storageRef = ref(this.storage, pathInStorage)
    const uploadTask = await uploadBytesResumable(storageRef, file)

    return await getDownloadURL(uploadTask.ref).then((downloadURL) => {
      return downloadURL
    })

    // return await uploadTask.on('state_changed',
    //   (snapshot) => { },
    //   (error) => {
    //     const errorMessage = `uploadFiles - ${pathInStorage} - ${file} - ${error}`
    //     loggerHelper.logError(errorMessage)
    //   },
    //   async () => {
    //     await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
    //       console.log(downloadURL)
    //       return downloadURL
    //     })
    //   })
    // return await uploadBytes(storageRef, file)
    //   .then((snapshot) => {
    //     snapshot.
    //     return pathInStorage
    //   })
    //   .catch((e: any) => {
    //     const error = `uploadFiles - ${pathInStorage} - ${file} - ${e}`
    //     loggerHelper.logError(error)
    //   })
  }

  async uploadFileAsString(pathInStorage: string, file: any, useBase64 = true) {
    if (useBase64) {
      return await this.storage
        .ref()
        .child(pathInStorage)
        .putString(file, 'base64')
        .catch((e: any) => {
          const error = `uploadFilesAsString - ${pathInStorage} - ${file} - ${e}`
          loggerHelper.logError('uploadFileAsString useBase64 ', error)
        })
    } else {
      return await this.storage
        .ref()
        .child(pathInStorage)
        .putString(file)
        .catch((e: any) => {
          const error = `uploadFilesAsString - ${pathInStorage} - ${file} - ${e}`
          loggerHelper.logError('uploadFileAsString else ', error)
        })
    }
  }

  async uploadJSON(pathInStorage: string, jsonData: any) {
    return await this.storage
      .ref()
      .child(pathInStorage)
      .putString(JSON.stringify(jsonData), 'raw')
      .catch((e: any) => {
        const error = `uploadJSON - ${pathInStorage} - ${jsonData} - ${e}`
        loggerHelper.logError('uploadJSON ', error)
      })
  }

  async downloadFile(pathInStorage: string) {
    return await ref(this.storage, pathInStorage)
      .download()
      .catch((e: any) => {
        const error = `downloadFile - ${pathInStorage} - ${e}`
        loggerHelper.logError('downloadFile', error)
      })
  }

  async downloadJSON(pathInStorage: string) {
    let result = null
    const fileRef = ref(this.storage, pathInStorage)
    const url = await fileRef.getDownloadURL()
    const res: any = await axios.get(url)
    if (res?.data) result = res?.data
    return result
  }

  async getFileDownloadURL(pathInStorage: string) {
    const fileRef = ref(this.storage, pathInStorage)
    const url = await getDownloadURL(fileRef)
    return url
  }
}

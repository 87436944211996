import { defineStore } from 'pinia'
import {
  defaultWorkflows,
  WorkflowType,
} from '../components/Settings/Workflows/config'
import { getWorkflows } from '~/controllers/workflows'
import type { JWorkflow } from '~/models/workflows/jWorkflow'

export const workflowStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: 'workflows',
  // a function that returns a fresh state
  state: () => ({
    workflows: [] as JWorkflow[],
    workflowTypes: [
      { type: WorkflowType.ALERTS, acl: 'change-status-alert' },
      { type: WorkflowType.DOCUMENTS, acl: 'change-status-document' },
      { type: WorkflowType.OPERATIONS, acl: 'change-status-document' },
      { type: WorkflowType.PRODUCTION_ORDERS, acl: 'change-status-report' },
      {
        type: WorkflowType.REPORTS,
        multiple: true,
        acl: 'change-status-report',
      },
      { type: WorkflowType.INCIDENTS, acl: 'change-status-alert' },
    ],
  }),
  getters: {
    getWorkflows(): any {
      return this.workflows
    },
    workflowTypesRights(): any {
      return this.workflowTypes
    },
  },
  // optional actions
  actions: {
    async loadWorkflows(): Promise<any> {
      const workflows = await getWorkflows()
      this.workflows = workflows
    },
    findWorkflow(id: string) {
      return this.workflows.find((wf: any) => wf.id === id)
    },
    findWorkflowByType(type: WorkflowType) {
      const isMultiple = this.workflowTypes.find(
        (e) => e.type === type,
      )?.multiple

      return isMultiple
        ? null
        : this.workflows.find((wf: any) => wf.type === type) ||
            defaultWorkflows.find((e) => e.type === type)
    },
    filterWorkflowByType(type: WorkflowType) {
      return this.workflows.filter((wf: any) => wf.type === type)
    },
  },
})

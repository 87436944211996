import { defineStore } from 'pinia'
import apiHelper from '~/helpers/ApiHelper'

export const routingStore = defineStore({
  id: 'routing',
  state: () => ({
    filters: [] as any[],
    routing: [] as any[],
  }),
  getters: {
    getRouting(): any {
      return this.routing
    },
  },
  actions: {
    async loadRouting(forceReload = false): Promise<any> {
      const routing = await apiHelper.getRouting(forceReload)

      this.routing = routing
    },
    filterRouting(
      productionOrderId: string,
      operationId: string,
      workplaceId: string,
    ): any {
      const filteredRecords = this.routing.filter((record) => {
        return (
          record.workorder_id === productionOrderId &&
          record.workplace_id === workplaceId &&
          record.operation_id === operationId
        )
      })

      // Filtrer les enregistrements supplémentaires
      const additionalRecords = this.routing.filter((record) => {
        return (
          record.operation_id === operationId &&
          record.workplace_id === workplaceId &&
          !filteredRecords.some((filteredRecord) => {
            return (
              filteredRecord.workorder_id === productionOrderId &&
              filteredRecord.workplace_id === workplaceId &&
              filteredRecord.operation_id === operationId
            )
          })
        )
      })

      // Combinez les enregistrements filtrés
      return [...filteredRecords, ...additionalRecords]
    },
  },
})

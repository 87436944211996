import { gridStore } from '~/store/grid'
import { siteStore } from '~/store/site'

export async function requestStreamingPort(): Promise<void> {
  if (!('serial' in navigator) || !siteStore().getFlag('read_balance')) return

  const isDataAvailable =
    !!gridStore().getSerialPortContext('measureInstantData')

  if (!isDataAvailable) {
    const port = await navigator.serial.requestPort()
    if (!port) {
      throw new Error('No port selected')
    }

    // init worker
    const worker = new Worker('/jsw.js')
    worker.postMessage({ type: 'startStreamingPort' })
  }
}

export async function startReadingFromSerialPort(): void {
  const bc = new BroadcastChannel('serial-port-data')

  bc.onmessage = (event) => {
    if (event.data === '%_done')
      gridStore().setSerialPortContext('measureInstantData', null)

    gridStore().setSerialPortContext('measureInstantData', event.data)
  }
}

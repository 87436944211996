import type {
  AlertApiModel,
  AlertHistory,
  AlertModel,
  AlertStatus,
  AlertViewModel,
  AlertViewRawModel,
} from '~/common/models/alerts'
import { STEPS_COLLECTION_NAME } from '~/config/storage'
import api from '~/helpers/ApiHelper'
import DbHelper from '~/helpers/dbHelper'
import {
  formatDate,
  getRelativeTime,
  recursivelyCamelizeObject,
} from '~/utils/formatter'
import { i18n } from '~/modules/i18n'
import { workplaceStore } from '~/store/workplaces'
import { siteStore } from '~/store/site'
import type { JobDataModel } from '~/common/models/jobdatainfo'

const dbHelper = new DbHelper()
const { t } = i18n.global

class AlertViewModelMapper {
  static async apiModelToViewModel(
    alertModel: AlertModel,
  ): Promise<AlertViewModel> {
    if (!alertModel) return {} as AlertViewModel

    const { id, clientId, siteId } = alertModel
    const {
      comment,
      stepIds,
      headerName,
      assigneeIds,
      erroredSteps,
      groupsIds,
    } = alertModel?.content
    //  const assignees = usersStore().filterUsers(assigneeIds)
    const steps = await dbHelper.getAllDataFromCollectionFromIds(
      STEPS_COLLECTION_NAME,
      stepIds,
    )
    const { unit: createdAtRelativeUnit, value: createdAtRelativeValue } =
      getRelativeTime(alertModel.createdAt as number)
    const { unit: updatedAtRelativeUnit, value: updatedAtRelativeValue } =
      getRelativeTime(alertModel.updatedAt as number)

    const workplaces = workplaceStore().getWorkplaces

    alertModel.sessionContext.workplaceId =
      workplaces.find((e) => e.id === alertModel.sessionContext?.workplaceId)
        ?.id || ''

    const alertHistory = alertModel.history
      .map((e) => this.historyApiModelToViewModel(e))
      .sort((a, b) =>
        a.createdAt > b.createdAt ? 1 : a.createdAt < b.createdAt ? -1 : 0,
      )

    return {
      id,
      clientId,
      siteId,
      createdBy: alertModel.createdBy!,
      updatedBy: alertModel.updatedBy!,
      createdAt: formatDate(alertModel.createdAt),
      createdAtTimestamp: alertModel.createdAt,
      originalAssignees: assigneeIds,
      originalGroups: groupsIds,
      createdAtRelative: t(
        `dates.${createdAtRelativeUnit}`,
        { amount: createdAtRelativeValue },
        createdAtRelativeValue,
      ),
      updatedAt: formatDate(alertHistory[alertHistory.length - 1].updatedAt),
      updatedAtRelative: t(
        `dates.${updatedAtRelativeUnit}`,
        { amount: updatedAtRelativeValue },
        updatedAtRelativeValue,
      ),
      assignees:
        alertModel.history[alertModel.history.length - 1].assignees ??
        assigneeIds,
      groupsIds:
        alertModel.history[alertModel.history.length - 1].groupsIds ??
        groupsIds,
      comment,
      headerName,
      erroredSteps,
      sessionContext: recursivelyCamelizeObject(alertModel.sessionContext),
      steps,
      status: siteStore().availableSiteStatuses.find(
        (e) =>
          e.id ===
          Number.parseInt(
            alertModel.history[alertModel.history.length - 1].status,
          ),
      )?.id,
      history: alertHistory,
    }
  }

  static historyApiModelToViewModel(
    alertHistoryModel: AlertHistory & JobDataModel,
  ): AlertHistory & JobDataModel {
    return {
      id: alertHistoryModel.id,
      type: alertHistoryModel.type,
      comment: alertHistoryModel.comment,
      status: alertHistoryModel.status,
      alertId: alertHistoryModel.alertId,
      clientId: alertHistoryModel.clientId,
      siteId: alertHistoryModel.siteId,
      createdBy: alertHistoryModel.createdBy,
      updatedBy: alertHistoryModel.updatedBy,
      createdAt: alertHistoryModel.createdAt,
      updatedAt: alertHistoryModel.updatedAt,
      assignees: alertHistoryModel.assignees,
      groupsIds: alertHistoryModel.groupsIds,
      startDate: formatDate(alertHistoryModel.startDate),
      endDate: formatDate(alertHistoryModel.endDate),
    }
  }
}

class AlertController {
  isAlertEnabled() {
    return siteStore().hasFlag({
      flagField: 'actions',
      flagType: 'alerts',
    })
  }

  async getAlertById(alertId: string): Promise<AlertViewModel> {
    const alert = await api.getAlertbyId(alertId)

    return AlertViewModelMapper.apiModelToViewModel(alert)
  }

  async getAlerts(query = {}): Promise<AlertViewModel[]> {
    const alerts = await api.getAlerts(query)

    return Promise.all(
      alerts.map(async (alert: AlertApiModel) =>
        AlertViewModelMapper.apiModelToViewModel(alert),
      ),
    )
  }

  async createAlert(alert: AlertViewRawModel) {
    try {
      let returnedData = await api.createAlert(alert)
      returnedData = AlertViewModelMapper.apiModelToViewModel(returnedData)
      return returnedData
    } catch (e) {
      console.error('Cannot create alert', e)
    }
  }

  async setAlertStatus(params: {
    alertId: string
    status: AlertStatus | string
  }): Promise<any> {
    const { alertId, status } = params

    try {
      await api.updateAlert(alertId, { status })
    } catch (e) {
      console.error('Cannot update alert', e)
    }
  }

  async addAlertHistoryEntry(historyEntryData: any) {
    try {
      const newAlertHistory = await api.addAlertHistoryEntry(
        historyEntryData.alertId,
        historyEntryData,
      )

      return AlertViewModelMapper.historyApiModelToViewModel(newAlertHistory)
    } catch (e) {
      console.error('Cannot update alert', e)
    }
  }
}

const alertController = new AlertController()

export default alertController

export const hydrate = (thisInstance: any, object: any) => {
  for (const [key] of Object.entries(thisInstance)) {
    if (object[key] != null && object[key] !== undefined)
      thisInstance[key] = object[key]
  }
}

export const removeInstance = (instanceObject: any) => {
  const { ...object } = instanceObject
  return object
}

export const isUndefinedOrNull = (obj: any): boolean => {
  return typeof obj === 'undefined' || obj == null
}

export const isUndefinedOrNullOrEmpty = (obj: any): boolean => {
  return typeof obj === 'undefined' || obj == null || obj === ''
}

export const isUndefinedOrNullOrEmptyOrNan = (obj: any): boolean => {
  return typeof obj === 'undefined' || obj == null || isNaN(obj) || obj === ''
}

import Cell from './Cell'
import type { JInputData } from '~/models/report/jInputData'
import { type JStep, StepType } from '~/models/documents/jStep'
import { capitalizeFirstLetter } from '~/utils/formatter'

export class ListCell extends Cell {
  private _options: any[]
  private _isMultiple: boolean
  private _selectedValues: string | string[]

  constructor(
    rowIndex: number,
    colIndex: number,
    inputs: JInputData[],
    step: JStep,
    isHistory: boolean,
    onExport: false,
    report_id: null,
  ) {
    if (step?.type !== StepType.List) throw new Error('Type mismatch')

    super(rowIndex, colIndex, inputs, step, isHistory, onExport, report_id)
    this._isMultiple = step?.details?.isMultiple || false
    this._selectedValues = super.latestValue
    this._isActivated =
      step.last_sampling_areas[colIndex] || this.latestValue !== ''
    this.initializeOptions()
  }

  private initializeOptions(): void {
    let options = this.step?.details?.selectOptions || []

    let customOptions =
      this._isMultiple && this.latestValue
        ? this.latestValue?.filter(
            (val) => !options?.map((e) => e.value)?.includes(val),
          )
        : []

    customOptions = customOptions?.map((option) => {
      return {
        label: capitalizeFirstLetter(option),
        value: option,
      }
    })

    options = options?.concat(customOptions)
    this._options = options?.sort((a: any, b: any) =>
      a.label > b.label ? 1 : a.label < b.label ? -1 : 0,
    )
  }

  addOption(label: string): void {
    this._options.push({ label, value: label })
  }

  get options(): any[] {
    return this._options
  }

  get latestValue(): string | string[] {
    const value = super.latestValue
    return value
  }

  get isMultiple(): boolean {
    return this._isMultiple
  }

  get selectedValues(): string | string[] {
    return this._selectedValues
  }

  selectOption(values: string | string[]): void {
    this._selectedValues = values
  }

  parseAnswer(): string {
    const answer: JInputData | null =
      this.restoreValue ?? this.justifyKoValue ?? this.getLatestInputData()
    return `${answer?.value}`
  }
}

export default ListCell

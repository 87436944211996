import { defineStore } from 'pinia'
import { usersStore } from './users'
import { JAction } from '~/models/projects/JAction'

export const actionsStore = defineStore({
  id: 'actions',
  state: () => ({
    actions: [] as JAction[],
  }),
  actions: {
    async loadActions() {
      const actions = [
        {
          id: 1,
          name: 'projects.actions.demo.action_1_title',
          description: 'projects.actions.demo.action_1_description',
          assigneesIds: [usersStore().user.id],
          projectId: 1,
          priority: 3,
          deadline: new Date(),
          status: 1,
          category: 'Production',
          attachments: ['Maintenance 1er niveau'],
        },
        {
          id: 2,
          name: 'projects.actions.demo.action_2_title',
          description: '',
          assigneesIds: [usersStore().user.id],
          projectId: 2,
          priority: 3,
          deadline: new Date(),
          status: 1,
          category: 'Production',
        },
        {
          id: 3,
          name: 'projects.actions.demo.action_3_title',
          description: '',
          assigneesIds: [usersStore().user.id],
          projectId: 3,
          priority: 3,
          deadline: new Date(),
          status: 3,
          category: 'Production',
          attachments: ['Maintenance 1er niveau'],
        },
        {
          id: 4,
          name: 'projects.actions.demo.action_4_title',
          description: '',
          assigneesIds: [usersStore().user.id],
          projectId: 2,
          priority: 3,
          deadline: new Date(),
          status: 1,
          category: 'Production',
        },
        {
          id: 5,
          name: 'projects.actions.demo.action_5_title',
          description: '',
          assigneesIds: [usersStore().user.id],
          projectId: 3,
          priority: 3,
          deadline: new Date(),
          status: 1,
          category: 'Production',
        },
        {
          id: 6,
          name: 'projects.actions.demo.action_6_title',
          description: '',
          assigneesIds: [usersStore().user.id],
          projectId: 1,
          priority: 3,
          deadline: new Date(),
          status: 2,
          category: 'Production',
        },
        {
          id: 7,
          name: 'projects.actions.demo.action_7_title',
          description: '',
          assigneesIds: [usersStore().user.id],
          projectId: 1,
          priority: 3,
          deadline: new Date(),
          status: 2,
          category: 'Production',
        },
        {
          id: 8,
          name: 'projects.actions.demo.action_8_title',
          description: '',
          assigneesIds: [usersStore().user.id],
          projectId: 1,
          priority: 3,
          deadline: new Date(),
          status: 2,
          category: 'Production',
        },
        {
          id: 9,
          name: 'projects.actions.demo.action_9_title',
          description: '',
          assigneesIds: [usersStore().user.id],
          projectId: 1,
          priority: 3,
          deadline: new Date(),
          status: 1,
          category: 'Production',
        },
      ]

      /* actions.map((e) => {
        e.category = settingsStore().findSettingById(e.category)?.value
      }) */

      this.actions = actions
    },
  },
})

import { getWeek } from 'date-fns'
import type { JStep } from './../models/documents/jStep'

export const isOddWeek = () => {
  const currentDate = new Date()
  const startDate = new Date(currentDate.getFullYear(), 0, 1)
  const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000))

  const weekNumber = Math.ceil(days / 7)
  const isOdd = weekNumber % 2 !== 0

  return isOdd
}

export const getWeekAnswers = (step: JStep) => {
  const weekAnswers: boolean[] = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]
  let lastAnswerDay = 0

  step.answers.forEach((answer) => {
    const answerDate = new Date(answer.update_date)
    if (getWeek(answerDate) === getWeek(new Date()))
      weekAnswers.splice(answerDate.getDay() - 1, 1, true)
  })
  for (let i = weekAnswers.length; i >= 0; i--) {
    if (weekAnswers[i] === true && lastAnswerDay === 0) lastAnswerDay = i + 1
  }
  weekAnswers.fill(true, 0, lastAnswerDay)
  return weekAnswers
}

export const toHours = (hour: number) => {
  return hour * 60 * 60 * 1000
}

export const seconds2time = (duration: number) => {
  const hours = Math.floor(duration / 3600)
  const minutes = Math.floor((duration - hours * 3600) / 60)
  const seconds = duration - hours * 3600 - minutes * 60
  let time = ''
  if (hours !== 0) time = `${hours}h`
  if (minutes !== 0) time += ` ${minutes}m`
  if (time === '') time = `${seconds}s`
  return time
}

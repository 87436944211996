import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import type { Ref } from 'vue'
import { trpc } from '../trpc'

export type TAllMachines = ReturnType<
  typeof useFetchAllMachines
>['data']['value']
export type TMachineStatuses = ReturnType<
  typeof useFindMachine
>['data']['value']

export type TMachineWorkplace =
  NonNullable<
    ReturnType<typeof useFindMachinesByWorkplace>['data']['value']
  > extends Array<infer T>
    ? T
    : null

export const useFetchAllMachines = () => {
  return useQuery({
    queryKey: ['fetchAllMachines'],
    queryFn: async () => await trpc.fetchAllMachines.query(),
    initialData: [],
  })
}

export const useFindMachine = (id: string) => {
  return useQuery({
    queryKey: ['findMachine', id],
    queryFn: async () =>
      trpc.fetchOneMachine.query({
        id,
      }),
    initialData: null,
    refetchOnWindowFocus: true,
    enabled: !!id,
  })
}

export const useFindMachinesByWorkplace = ({
  workplaceId,
}: {
  workplaceId: Ref<number>
}) => {
  const enabled = ref(false)

  watchEffect(() => {
    enabled.value = !!workplaceId.value
  })

  return useQuery({
    queryKey: ['findMachinesByWorkplace', workplaceId],
    queryFn: async () =>
      trpc.fetchMachinesForWorkplace.query({
        workplaceId: workplaceId.value,
      }),
    initialData: null,
    refetchOnWindowFocus: true,
    enabled,
  })
}

export const useFetchItemScrapOnMachine = (
  machineId: string,
  tagsIds: string[],
) => {
  return useQuery({
    queryKey: ['fetchItemScrapOnMachine', machineId],
    queryFn: async () =>
      trpc.fetchItemScrapOnMachine.query({
        machineId,
        tagsIds,
      }),
    initialData: null,
    refetchOnWindowFocus: true,
    enabled: !!machineId,
    refetchInterval: 3000,
  })
}

interface ItemScrapParams {
  machineId: string
  operatorId: string
  quantity: number
}

export const useSetItemScrapOnMachine = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (itemScrap: ItemScrapParams) =>
      trpc.setItemScrap.mutate(itemScrap),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fetchItemScrapOnMachine'] })
    },
  })
}

export const useFetchProductionRateOnMachine = (machineId: string) => {
  return useQuery({
    queryKey: ['fetchProductionRateOnMachine', machineId],
    queryFn: async () =>
      trpc.fetchProductionRateOnMachine.query({
        machineId,
      }),
    initialData: null,
    refetchOnWindowFocus: true,
    enabled: !!machineId,
  })
}

interface ProductionRateParams {
  machineId: string
  operatorId: string
  productionRate: number
}

export const useSetProductionRateOnMachine = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (productionRate: ProductionRateParams) =>
      trpc.setProductionRate.mutate(productionRate),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['fetchProductionRateOnMachine'],
      })
    },
  })
}

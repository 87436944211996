export enum CustomInput {
  DatePicker = -8,
}

export enum inputType {
  TEXT = 'text',
  PASSWORD = 'password',
}

export enum TableIconsType {
  LEFT = 'left_icon',
  RIGHT = 'right_icon',
  END = 'extreme_right_icon',
}

export enum ModalType {
  success = 'success',
  error = 'error',
  newError = 'newError',
}

export const NO_GROUPING = 'no_grouping'

export interface IUpdateFilters {
  value: string | Date | number
  label: string
  checked: boolean | string
  sectionId: string
}

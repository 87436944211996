import { AUDIT_LOGS_COLLECTION_NAME } from '~/config/storage'
import DBHelper from '~/helpers/dbHelper'
import loggerHelper from '~/helpers/LoggerHelper'
import type { AddNewAuditLogBody } from '~/models/auditLogs/JAuditLogs'
import { JAuditLogs } from '~/models/auditLogs/JAuditLogs'
import { usersStore } from '~/store/users'
import { removeInstance } from '~/utils/object'
import { formatDate } from '~/utils/formatter'
import { rolesStore } from '~/store/roles'
import { JUser } from '~/models/users/JUser'

const dbHelper = new DBHelper()

class AuditLogsController {
  async getAuditLogs() {
    const user = usersStore().user
    const auditLogs = await dbHelper?.getAllDataFromCollectionWithWhereArray(
      AUDIT_LOGS_COLLECTION_NAME,
      {
        site_id: user.site_id,
        client_id: user.client_id,
      },
    )

    return auditLogs
      .sort((a, b) => b.time - a.time)
      .map((e) => {
        const user = usersStore().findUser(e.user_id) as JUser
        const roles = rolesStore()
          .findRoleByIds(e.role_ids || user.role_ids)
          .map((e) => e.name)
          .join(', ')

        return {
          userId: user?.id,
          roles,
          time: e.time,
          formattedTime: formatDate(e.time),
          user_log_action: e.action,
        }
      })
  }

  async createNewAudit(data: AddNewAuditLogBody) {
    const { user } = usersStore()
    const body = {
      user_id: data.user_id ?? user.id,
      role_ids: data.role_ids ?? user.role_ids,
      site_id: data.site_id ?? user.site_id,
      client_id: data.client_id ?? user.client_id,
      action: data.action,
    }
    const newAudit = removeInstance(new JAuditLogs(body))

    try {
      await dbHelper.addDataToCollection(AUDIT_LOGS_COLLECTION_NAME, newAudit)
    } catch (err) {
      loggerHelper.logError('Error creating audit logs', err)
    }
  }
}

const auditLogsController = new AuditLogsController()

export default auditLogsController

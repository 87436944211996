import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import type { Ref } from 'vue'
import type { RouterInput } from '@mesx-server/trpc/router'
import { trpc } from '../trpc'

export const useFetchSimulatorTagStatus = (param: { tagId: Ref<string> }) => {
  return useQuery({
    queryKey: ['fetchSimulatorTagStatus', param.tagId.value],
    queryFn: async () =>
      trpc.fetchSimulatorTagStatus.query({
        tagId: param.tagId.value,
      }),
  })
}

export const useMutateSimulatorToggleCycleTag = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (props: RouterInput['simulatorToggleCycleTag']) =>
      trpc.simulatorToggleCycleTag.mutate(props),
    onSuccess: () => {
      queryClient.invalidateQueries()
    },
  })
}

export const useMutateSimulatorToggleScrapTag = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (props: RouterInput['simulatorToggleScrapTag']) =>
      trpc.simulatorToggleScrapTag.mutate(props),
    onSuccess: () => {
      queryClient.invalidateQueries()
    },
  })
}

export const useMutateSimulatorToggleStatusTag = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (props: RouterInput['simulatorToggleStatusTag']) =>
      trpc.simulatorToggleStatusTag.mutate(props),
    onSuccess: () => {
      queryClient.invalidateQueries()
    },
  })
}

<script setup lang="ts">
import { trpc } from '~/composables/mesx/trpc'
import { useQueryClient } from '@tanstack/vue-query'
import { TagMachine } from '~/composables/mesx/requests/tags'
import { ref, toRefs } from 'vue'

const isGeneratingData = ref(false)
const queryClient = useQueryClient()

const props = defineProps<{
  tagId: string
  machines: TagMachine['machineToTag']
}>()

const { tagId, machines } = toRefs(props)
const generateData = async () => {
  try {
    isGeneratingData.value = true
    const requests = [] as Promise<never>[]
    for (const machine of machines.value) {
      requests.push(
        trpc.generateScrapData.mutate({
          tagId: tagId.value,
        }),
      )
    }
    await Promise.all(requests)
    queryClient.invalidateQueries()
  } catch (e) {
    console.error(e)
  } finally {
    isGeneratingData.value = false
  }
}
</script>

<template>
  <OButton
    :disabled="isGeneratingData"
    :loading="isGeneratingData"
    @click="generateData"
  >
    Generate data for the last 24 hours
  </OButton>
</template>

import { hydrate } from '~/utils/object'

export enum JAuditLogsAction {
  login = 'login',
  logout = 'logout',
  deactivate = 'deactivate',
  reactivate = 'reactivate',
}

export class JAuditLogs {
  id = ''
  time = Date.now()
  user_id = ''
  client_id = ''
  site_id = ''
  role_ids = []
  action = '' as JAuditLogsAction

  constructor(object: typeof JAuditLogs | {}) {
    hydrate(this, object)
  }
}

export interface AddNewAuditLogBody {
  user_id?: string
  site_id?: string
  client_id?: string
  role_ids?: number[]
  action: JAuditLogsAction
}

<script setup lang="ts">
import { MIcons } from '~/models/Icon'
import { siteStore } from '~/store/site'
import { usersStore } from '~/store/users'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import SimulatorMESx from './SimulatorMESx.vue'
import SimulatorJuno from './SimulatorJuno.vue'

const isDeveloper = computed(() => usersStore().user?.is_developer)
const isModalOpened = ref(false)

const visibleTabs = computed(() => {
  return [
    siteStore().site.flags?.mes &&
      isDeveloper.value && {
        name: 'MESx',
        component: SimulatorMESx,
      },
    siteStore().site.flags?.generate_derivation_data && {
      name: 'Juno',
      component: SimulatorJuno,
    },
  ].filter(Boolean)
})
</script>

<template>
  <section v-if="visibleTabs.length">
    <div
      class="absolute bottom-[120px] right-6 z-50 grid h-12 w-12 cursor-pointer place-items-center rounded-full bg-white shadow-xl transition-opacity ease-out"
      :onclick="() => (isModalOpened = true)"
    >
      <JIcon
        :icon="MIcons.SMART_TOY"
        class="text-3xl"
      />
    </div>

    <ActiveModal v-model="isModalOpened">
      <template #body>
        <div class="min-w-[70vw]">
          <TabGroup>
            <TabList class="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
              <Tab
                v-for="tab in visibleTabs"
                :key="tab.name"
                v-slot="{ selected }"
                as="template"
              >
                <button
                  :class="[
                    'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                    'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                    selected && 'bg-white text-blue-700 shadow',
                  ]"
                >
                  {{ tab.name }}
                </button>
              </Tab>
            </TabList>

            <TabPanels class="mt-2">
              <TabPanel
                v-for="tab in visibleTabs"
                :key="tab.name"
                :class="[
                  'rounded-xl bg-white p-3',
                  'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                ]"
              >
                <component :is="tab.component" />
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
      </template>
    </ActiveModal>
  </section>
</template>

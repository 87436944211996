import type { CamelToSnakeCase, Nullable, SnakeToCamelCase } from './helpers'
import type { JobDataApiModel, JobDataModel } from './jobdatainfo'

export enum AlertStatus {
  NEW = 1,
  IN_PROGRESS,
  FINISHED,
}

export enum AlertListType {
  INCIDENTS = 'incidents',
  INSTRUCTIONS = 'instructions',
}

export enum AlertHistoryType {
  CREATED = 'created',
  ASSIGNED = 'assigned',
  COMMENTED = 'commented',
  STATUS_CHANGED = 'status_changed',
  ASSIGNEE_CHANGED = 'assignee_changed',
  UPDATED = 'updated',
}

export interface SessionContext {
  productionOrderId: string
  workplaceId: string
  productId: string
  operationId: string
  tags: string[]
  documentId: string
  documentName: string
  reportId: string
}

interface AlertContent {
  assigneeIds: string[]
  groupsIds: number[]
  comment: string
  headerName: string
  stepIds: string[]
  erroredSteps: any[]
  workflowId: Nullable<string>
}

export interface AlertHistory {
  id?: number
  alertId: number
  comment: string
  status: AlertStatus
  type: AlertHistoryType
  assignees: string[] | null
  groupsIds: number[] | null
  startDate: Date | number
  endDate: Nullable<Date | number>
}

export type SessionContextView = {
  [K in keyof SessionContext as SnakeToCamelCase<K>]: SessionContext[K]
}

type AlertContentApi = {
  [K in keyof AlertContent as CamelToSnakeCase<K>]: AlertContent[K]
}

type AlertHistoryApi = {
  [K in keyof AlertHistory as CamelToSnakeCase<K>]: AlertHistory[K]
}

export interface AlertViewRawModel {
  id?: string
  assigneeIds?: string[]
  groupsIds?: number[]
  comment: string
  headerName: string
  sessionContext: Partial<SessionContextView>
  stepIds?: string[]
  erroredSteps: any[]
  status: AlertStatus
  workflowId?: string | null
  history: AlertHistory[]
}

export interface AlertApiRawModel {
  id?: string
  content: AlertContentApi
  session_context: Partial<SessionContext>
}

export interface AlertViewModel extends AlertViewRawModel, JobDataModel {
  assignees: any[]
  steps: any[]
}

export interface AlertApiModel extends AlertApiRawModel, JobDataApiModel {}

export interface AlertRawModel {
  id?: string
  content: AlertContent
  sessionContext: Partial<SessionContext>
  history: AlertHistory[]
}

export interface AlertModel extends AlertRawModel, JobDataModel {}

export class AlertModelMapper {
  static alertModelToApiModel(alertModel: AlertViewRawModel): AlertApiRawModel {
    return {
      content: {
        assignee_ids: alertModel.assigneeIds || [],
        groups_ids: alertModel.groupsIds || [],
        comment: alertModel.comment,
        step_ids: alertModel.stepIds || [],
        errored_steps: alertModel.erroredSteps,
        workflow_id: null,
        header_name: alertModel.headerName,
      },
      session_context: alertModel.sessionContext,
    }
  }
}

export class AlertHistoryModelMapper {
  static alertHistoryModelToApiModel(
    alertHistoryModel: AlertHistory,
  ): AlertHistoryApi {
    return {
      alert_id: alertHistoryModel.alertId,
      comment: alertHistoryModel.comment,
      assignees: alertHistoryModel.assignees,
      groups_ids: alertHistoryModel.groupsIds,
      status: alertHistoryModel.status,
      type: alertHistoryModel.type,
      start_date: new Date(),
      end_date: null,
    }
  }
}

import { defineStore } from 'pinia'
import { getAllSessions, getSession } from '~/controllers/sessions'
import type { JSession, SessionStatus } from '~/models/sessions/JSession'
import apiHelper from '~/helpers/ApiHelper'

export const sessionStore = defineStore({
  id: 'sessions',
  state: () => ({
    sessions: [] as JSession[],
  }),
  actions: {
    async loadSessions(forceRefetch = false): Promise<void> {
      if (this.sessions?.length > 0 && !forceRefetch) return

      const sessions = await getAllSessions()

      this.sessions = sessions
    },
    addSession(session) {
      this.sessions.push(session)
    },
    addSessions(sessions) {
      this.sessions.push(...sessions)
    },
    async addAutomaticWorkOrdersSessions() {
      const automaticProductionOrders = await apiHelper.getProductionOrders(
        { only_automatic: true },
        true,
      )

      if (automaticProductionOrders.length) {
        automaticProductionOrders.forEach((item) => {
          this.sessions.push({
            session_context: {
              production_order_id: item.id,
            },
            update_date: new Date(item.updateDate).getTime(),
            type: 'automatic',
          })
        })
      }

      return this.sessions
    },
    async findSession(id: string): Promise<JSession> {
      return (
        this.sessions.find((u: any) => u?.id === id) ||
        ((await getSession(id)) as JSession)
      )
    },
    async filterSessionByIds(ids: string[]) {
      return this.sessions.filter((session) => ids.includes(session.id))
    },
    findSessionByReportId(reportId: number): JSession {
      return this.sessions.find((e) => e.reports_ids.includes(reportId))
    },
    findSessionByParameters(
      productionOrderId: string,
      workplaceId: string,
      operationId: string,
    ): JSession {
      return this.sessions.find(
        (u: any) =>
          u?.session_context.production_order_id === productionOrderId &&
          u?.session_context.workplace_id === workplaceId &&
          u?.session_context.operation_id === operationId,
      )
    },
    filterSessionsByProductionId(productionOrderId: string): JSession[] {
      return this.sessions.filter(
        (u: any) =>
          u?.session_context.production_order_id === productionOrderId,
      )
    },
    async findSessionsOnWorkplace(
      workplaceId: string,
      options = { maxDays: 30 },
    ) {
      const expiration = new Date()
      expiration.setDate(expiration.getDate() - options.maxDays)
      // const favorites = userStore().user.favorites?.find(favorite => favorite.type === 'sessions')?.value ?? []

      const workplaceSessions = this.sessions.filter((session: JSession) => {
        return (
          session.session_context.workplace_id === workplaceId &&
          // && (session.start_date > expiration.getTime() || favorites.includes(session.id))
          session.start_date > expiration.getTime()
        )
      })

      return workplaceSessions
    },
    filterSessions(ids: any) {
      const sessions: any = this.sessions.filter((u: any) => {
        return ids?.length && ids?.includes(u?.id)
      })

      return sessions
    },
    setSessionStatusById(id: string, status: SessionStatus, isValid?: boolean) {
      const index = this.sessions.findIndex((session) => session.id === id)
      this.sessions[index].status = status
      this.sessions[index].is_valid = isValid
      this.sessions[index].update_date = new Date()
    },
  },
})

import type { JUser } from '~/models/users/JUser'
import localforage from 'localforage'

export const getUserLanguage = async (user: JUser): Promise<string> => {
  if (user?.language) {
    return user?.language
  }

  return (
    (await localforage.getItem('user-language')) ??
    navigator.language.substring(0, 2) ??
    'en'
  )
}

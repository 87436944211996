import type { UserModule } from '~/types'

export const install: UserModule = ({ isClient, router }) => {
  if (!isClient) return

  router.beforeEach(async (to, from, next) => {
    const { redirectTo } = to.meta

    if (redirectTo) {
      next({
        name: redirectTo as string,
      })
    } else {
      next()
    }
  })
}

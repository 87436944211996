import { defineStore } from 'pinia'
import { siteStore } from './site'
import type { JSite } from '~/models/sites'
import DeviceController from '~/controllers/devices'

export const deviceStore = defineStore({
  id: 'device',
  state: () => ({
    devices: [] as any,
    subscribedDevices: [] as any,
  }),
  actions: {
    async loadDevices(): Promise<any> {
      if (!siteStore().getFlag('automatic_devices')) return []

      const devices: JSite = await DeviceController.getDevices()

      this.devices = devices
    },
    async subscribeToDevice(deviceName: string, callback): Promise<any> {
      const subscribedDevice = await DeviceController.listenDevice(
        deviceName,
        callback,
      )

      this.subscribedDevices.push(subscribedDevice)
    },
    async unsubscribeToDevices(): Promise<any> {
      this.subscribedDevices.forEach((unsubscribe) => unsubscribe())
      this.subscribedDevices = []
    },
  },
})

import { defineStore } from 'pinia'
import { isOperatorView } from '~/utils/helpers'
export const gS = defineStore({
  id: 'global',

  state: () => ({
    global: {
      sessionId: '',
      workplace_id: '',
      product_id: '',
    } as any,
    online: true,
    lastActivity: new Date(),
  }),

  getters: {
    isOnline(state): boolean {
      return state.online && window.navigator.onLine
    },
    isOffline(state): boolean {
      return !state.online || !window.navigator.onLine
    },
    canUseCache(state): boolean {
      return this.isOffline && isOperatorView()
    },
    getLastActivity(): Date {
      return this.lastActivity
    },
  },

  actions: {
    setLineStatus(status) {
      this.online = status
    },
    setGlobal(obj: object) {
      this.global = Object.assign(this.global, obj)
    },
    resetGlobal() {
      this.global = {}
    },
    setLastActivity(lastActivity: Date) {
      this.lastActivity = lastActivity
    },
  },
})

// pouvoir modifier les champs dans la table
// figer des colones de la table
// scroller en horizontal dans la table

// appliquer table partout
// appliquer select partout

import api from '~/helpers/ApiHelper'
import DBHelper from '~/helpers/dbHelper'

const dbHelper = new DBHelper()
class DeviceController {
  async getDevices() {
    return await api.getDevices()
  }

  async listenDevice(deviceName: string, callback: () => Promise<any>) {
    return dbHelper.setupCollectionSnapshot({
      collectionName: 'event_triggers',
      checks: [
        {
          field: 'id',
          compare: '==',
          value: `device_${deviceName}`,
        },
      ],
      callback,
    })
  }
}

const deviceController = new DeviceController()

export default deviceController

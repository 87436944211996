import { defineStore } from 'pinia'
import { getAllDocumentSettings } from '~/controllers/settings'
import type {
  DocumentSetting,
  RepetitionType,
} from '~/models/documents/documentSettings'
import { DocumentSettingsType } from '~/models/documents/documentSettings'

export const documentSettingsStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: 'documentSettings',
  // a function that returns a fresh state
  state: () => ({
    documentSettings: [] as DocumentSetting[],
  }),

  // optional getters
  getters: {
    getDocumentSettings(): any {
      return this.documentSettings
    },
  },
  // optional actions
  actions: {
    async loadDocumentSettings(): Promise<any> {
      const documentSettings = await getAllDocumentSettings()
      this.documentSettings = documentSettings
    },
    find(documentSettingsId: string) {
      return this.documentSettings.find(
        (documentSetting) => documentSetting.id === documentSettingsId,
      )
    },
    goToDocumentSettingsValues(documentSettingsId: string) {
      return this.documentSettings
        .find((e: any) => e.id === documentSettingsId)
        ?.list_value.filter((e) => !e.deleted_at)
        .map((e) => ({
          label: e.option_key,
          value: e.option_value,
        }))
    },
    filterDocumentSettings(
      documentSettingType: DocumentSettingsType | RepetitionType,
      withRepetition = true,
    ) {
      const documentSettings: any = this.documentSettings.filter(
        (u: any) =>
          documentSettingType === u.type ||
          (documentSettingType === u.repetition_type && withRepetition),
      )

      return documentSettings
    },

    filterRepetitions(repetitionType: RepetitionType) {
      return this.documentSettings.filter(
        (setting: any) =>
          repetitionType === setting.repetition_type &&
          DocumentSettingsType.repetition === setting.type,
      )
    },
  },
})
